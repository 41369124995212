import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoDark from '../../../assets/images/howitworks-2.png';
import Logo from '../../../assets/images/logo.svg';
import PopupAlert from '../../../components/PopupAlert';
import WebRoutes from '../../WebRoutes';

export const BenefitList = ({ dataBenefits }) => {
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({
    title: null,
    show: false,
  });
  return (
    <>
      <div>
        {dataBenefits.map(({ heading, description, image, detail, isButton }, index) => (
          <div
            key={index}
            className={`benefit-container ${
              index % 2 === 0 ? 'bg-black' : 'bg-white position-relative'
            } ${index === 0 ? 'benefit-container-top-border' : ''}`}
          >
            <div
              className={`container py-0 d-flex align-items-center row ${
                index % 2 === 0 ? 'flex-row ' : 'flex-row-reverse bg-white'
              }  justify-content-between`}
            >
              <div
                className={`col-lg-6 d-flex ${
                  index % 2 === 0 ? 'justify-content-lg-start' : 'justify-content-lg-end'
                } justify-content-center`}
              >
                <div className='flex-column py-3'>
                  <h2
                    className={` m-0 text-center text-lg-start ${
                      index % 2 === 0 ? 'text-white' : 'text-dark'
                    }`}
                    data-aos='fade-up'
                    data-aos-duration='1200'
                    data-aos-once='true'
                  >
                    {heading}
                  </h2>

                  <p
                    className={`text-center text-lg-start mt-3 text-orange`}
                    data-aos='fade-up'
                    data-aos-duration='1600'
                    data-aos-once='true'
                  >
                    {description}
                  </p>

                  <p
                    className={`text-center text-lg-start mt-3 ${
                      index % 2 === 0 ? 'text-white' : 'text-dark'
                    }`}
                    data-aos='fade-up'
                    data-aos-duration='1600'
                    data-aos-once='true'
                  >
                    {detail}
                    {isButton?.Label ? (
                      <>
                        <br />
                        <span>
                          {/* removed class "className="text--primary-hover" */}
                          {isButton?.Label}
                        </span>
                      </>
                    ) : null}
                  </p>
                </div>
              </div>
              <div
                className={`col-lg-6 d-flex py-0 ${
                  index % 2 === 0 ? 'justify-content-lg-end' : 'justify-content-lg-start'
                } justify-content-center`}
              >
                {/*   {image ? ( */}
                <img
                  style={{ width: '100%' }}
                  src={image ? image : index % 2 === 0 ? Logo : LogoDark}
                  data-aos={index % 2 === 0 ? 'fade-left' : 'fade-right'}
                  data-aos-duration='1000'
                  data-aos-once='true'
                  alt='trip merchant logo'
                  className={image ? 'img-benefits-2' : 'img-benefits'}
                />
                {/*  ) : null} */}
              </div>
            </div>
          </div>
        ))}

        <div className='container p-lg-5 p-2 py-3 d-flex flex-column justify-content-center align-items-center'>
          <p className='text-white text-center become-member-text col-lg-10 col-sm-12 font-500'>
            *If you are interested in becoming a Trip Merchant member, for only
            $99 for the year, you will not only receive all these great member
            benefits and savings, but we will also convert that $99 into travel
            reward dollars for you to use towards future trips!
          </p>
          <button
            onClick={() => navigate(WebRoutes.signup)}
            className='p-2  px-md-5 text-white text-unselectable bg-cust-primary font-600 w-sm-100 btn-trial border-radius'
          >
            START YOUR FREE 14 DAY TRIAL
          </button>
        </div>
        <div className='border-gray-bottom' />
      </div>
      <PopupAlert
        state={modalData}
        close={() =>
          setModalData({
            title: null,
            show: false,
          })
        }
      />
    </>
  );
};

/* onClick={() =>
                              setModalData({
                                title: heading,
                                show: true,
                              })
                            } */
