import React, { useState } from "react";
import RotateButton from "./RotateButton";
import { strapiUrl } from "../common/strapiUrl";
import PopupAlert from "./PopupAlert";
import WebRoutes from "../pages/WebRoutes";
let initialValue = {
  title: null,
  show: false,
  navigate: null,
};

const WarningBanner = ({ data, setModalData }) => {
  return (
    <>
      <div className="info_warning_banner ">
        <div
          style={
            data?.BgImage?.data
              ? {
                  backgroundImage: `url(${strapiUrl}${data?.BgImage?.data?.attributes?.url})`,
                }
              : {}
          }
          className="banner"
        >
          <div className="overlay">
            <div className="wrapper">
              <div className="container mt-3 mb-2 py-2">
                <h2 className="fw-bolder mb-2">
                  {data?.Heading
                    ? data?.Heading
                    : "Trip Merchant Journeys Private / Tailor Made"}
                </h2>
                <p className="py-2">
                  {data?.paragraph
                    ? data?.paragraph
                    : `If any of the above departure dates do not work for you, but you
                wish to have a similar experience for dates that work better
                with your schedule, then Trip Merchant Journeys can assist in
                designing tailor made travel experiences to match your taste and
                budget – whether it be a private trip for 2 or more travellers,
                a family/friend reunion or a large group. You have the
                flexibility to choose your accommodations, meals and sightseeing
                based on your preferences, and at your desired pace. We deliver
                a stress free ‘a la carte’ travel experience, where we will look
                after all the small details.`}
                </p>
                <p>
                  <strong>
                    {data?.boldparagraph
                      ? data?.boldparagraph
                      : "Book your custom journey today!"}
                  </strong>
                </p>
                <br />
                <RotateButton
                  text={data?.btnText ? data?.btnText : "INQUIRE HERE"}
                  onClick={
                    data?.BtnLink == "openmodal"
                      ? () => {
                          setModalData({
                            title: "Trip Merchant Journeys Trips",
                            show: true,
                            navigate: WebRoutes.generalSubscription,
                          });
                        }
                      : undefined
                  }
                  link={
                    data?.BtnLink == "openmodal" ? undefined : data?.BtnLink
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarningBanner;
