import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "../pages/TripMerchantOrganization/LandingPage";
import { ForgetPassword } from "./Authentication/ForgetPassword";
import { GeneralSubscriptionPage } from "./Authentication/GeneralSubscriptionPage";
import { HomePage } from "./Authentication/HomePage";
import Individual from "./Authentication/Individual/IndividualTripFilter";
import { SignInPage } from "./Authentication/SignInPage";
import { SignUpPage } from "./Authentication/SignUpPage";
import { NotFound404 } from "./NotFound404";
import BenefitsPage from "./TripMerchantOrganization/BenefitsPage";
import ContactUs from "./TripMerchantOrganization/ContactUs";
import { EmployersFormPage } from "./TripMerchantOrganization/EmployersFormPage";
import { EmployersPage } from "./TripMerchantOrganization/EmployersPage";
import HowItWorks from "./TripMerchantOrganization/HowItWorks";
import { MembershipBasedOrganizations } from "./TripMerchantOrganization/MembershipBasedOrganizations";
import { MembershipBasedOrganizationsForm } from "./TripMerchantOrganization/MembershipBasedOrganizationsForm";
import OrganizationHomePage from "./TripMerchantOrganization/OrganizationHomePage";
import { PartnerWithUsPage } from "./TripMerchantOrganization/PartnerWithUsPage";
import ScheduleADemo from "./TripMerchantOrganization/ScheduleADemo";
import { TravelSuppliersForm } from "./TripMerchantOrganization/TravelSuppliersForm";
import { TravelSuppliersPage } from "./TripMerchantOrganization/TravelSuppliersPage";
import WhoWeAre from "./TripMerchantOrganization/WhoWeAre";
import WebRoutes from "./WebRoutes";
import { ResetPassword } from "./Authentication/ForgetPassword/ResetPassword";
import FAQ from "./FooterPages/FAQ";
import Testimonials from "./FooterPages/Testimonials";
import PrivacyPolicy from "./FooterPages/PrivacyPolicy";
import TermAndConditions from "./FooterPages/TermsAndConditions";
import TripMerchantJourneys from "./Authentication/GeneralSubscriptionPage/TripMerchantJourneys";
import SoloTraveler from "./Authentication/GeneralSubscriptionPage/SoloTraveler";
import Rewards from "./Authentication/GeneralSubscriptionPage/Rewards";
import { VerificationEmail } from "./Authentication/VerificationEmail";

export const AuthRouter = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [[pathname]]);

  return (
    <Routes>
      <Route path={WebRoutes.home} element={<HomePage />} />
      <Route path={WebRoutes.individual} element={<Individual />} />
      <Route path={WebRoutes.signup} element={<SignUpPage />} />
      <Route path={WebRoutes.forgetPassword} element={<ForgetPassword />} />
      <Route
        path={WebRoutes.generalSubscription}
        element={<GeneralSubscriptionPage />}
      />
      <Route
        path={WebRoutes.tripmerchant}
        element={
          <LandingPage hideAction={true} Component={OrganizationHomePage} />
        }
      />
      <Route path={WebRoutes.signin} element={<SignInPage />} />
      <Route
        path={WebRoutes.verificationEmail}
        element={<VerificationEmail />}
      />
      <Route
        path={WebRoutes.benefits}
        element={<LandingPage Component={BenefitsPage} />}
      />
      <Route
        path={WebRoutes.travelSuppliers}
        element={<LandingPage Component={TravelSuppliersPage} />}
      />
      <Route
        path={WebRoutes.travelSuppliersForm}
        element={
          <LandingPage hideAction={true} Component={TravelSuppliersForm} />
        }
      />
      <Route
        path={WebRoutes.howItWorks}
        element={<LandingPage Component={HowItWorks} />}
      />
      <Route
        path={WebRoutes.WhoWeAre}
        element={<LandingPage Component={WhoWeAre} />}
      />
      <Route
        path={WebRoutes.Schedule}
        element={<LandingPage hideAction={true} Component={ScheduleADemo} />}
      />
      <Route
        path={WebRoutes.membershipBasedOrganizations}
        element={
          <LandingPage
            hideAction={true}
            Component={MembershipBasedOrganizations}
          />
        }
      />
      <Route
        path={WebRoutes.membershipBasedOrganizationsForm}
        element={
          <LandingPage
            hideAction={true}
            Component={MembershipBasedOrganizationsForm}
          />
        }
      />
      <Route
        path={WebRoutes.employers}
        element={<LandingPage Component={EmployersPage} />}
      />
      <Route
        path={WebRoutes.employersForm}
        element={
          <LandingPage hideAction={true} Component={EmployersFormPage} />
        }
      />
      <Route
        path={WebRoutes.faq}
        element={<LandingPage hideAction={true} Component={FAQ} />}
      />
      <Route
        path={WebRoutes.testimonials}
        element={<LandingPage hideAction={true} Component={Testimonials} />}
      />
      <Route
        path={WebRoutes.partnerWithUs}
        element={<LandingPage Component={PartnerWithUsPage} />}
      />
      <Route
        path={WebRoutes.ContactUs}
        element={<LandingPage Component={ContactUs} />}
      />
      <Route
        path={WebRoutes.privacy}
        element={<LandingPage Component={PrivacyPolicy} />}
      />
      <Route
        path={WebRoutes.terms}
        element={<LandingPage Component={TermAndConditions} />}
      />
      <Route path={WebRoutes.resetPassword} element={<ResetPassword />} />
      <Route
        path={WebRoutes.tripmerchantJourneys}
        element={
          <LandingPage hideAction={true} Component={TripMerchantJourneys} />
        }
      />
      <Route
        path={WebRoutes.soloTraveler}
        element={<LandingPage hideAction={true} Component={SoloTraveler} />}
      />
      <Route
        path={WebRoutes.rewards}
        element={<LandingPage hideAction={true} Component={Rewards} />}
      />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};
