import React, { useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { PrimaryButton } from "../../../components/Button/PrimaryButton/PrimaryButton";
/* import { AuthHeader } from "../../../components/Header/AuthHeader/AuthHeader"; */
import Header from "../../../components/Header/Navbar/Header";
import WebRoutes from "../../WebRoutes";
import { HomePageBottomIcons } from "../HomePage/HomePageBottomIcons";
import "./forget-password.scss";
import PasswordInput from "./PasswordInput";
import { ResetPasswordValidation } from "../../../validation";
import Title from "../../../components/Title";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import { toast } from "react-hot-toast";
let initialState = {
  newPassword: null,
  confirmPassword: null,
};

export const ResetPassword = () => {
  const { id, email } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState(initialState);
  const { newPassword, confirmPassword } = password;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  const onChangeHandler = (name, value) => {
    setPassword({ ...password, [name]: value });
    if (Object.keys(errors).length > 0) {
      if (errors.hasOwnProperty(name)) delete errors[name];
    }
    // setErrors(ResetPasswordValidation({ ...password, [name]: value }))
  };

  const resetHandler = async () => {
    const er_ror = ResetPasswordValidation(password);
    if (er_ror.length > 0 || Object.keys(er_ror).length > 0) {
      setErrors(er_ror);
      return;
    }
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("email", email);
    formDataApi.append("password", newPassword);
    formDataApi.append("password_confirmation", confirmPassword);
    formDataApi.append("token", id);
    const response = await ApiCall.any(
      apiRoutes.resetPassword,
      {},
      formDataApi
    );
    if (response !== -1) {
      toast.dismiss();
      toast.success(
        response?.message || "Password has been reset successfully"
      );
      navigate(WebRoutes.signin);
    }
    setLoading(false);
  };
  // eslint-disable-next-line
  const setValidations = (data) => {
    const er_ror = ResetPasswordValidation(data);
    setErrors(er_ror);
  };
  /* d-flex justify-content-center align-items-between */
  return (
    <>
      <Title title="Trip Merchant | Forget Password" />

      <div className="full-screen-bg" style={{ minHeight: "100vh" }}>
        <Header
          light={true}
          hide={true}
          organizationWebLinks={true}
          /* link={"register"} */
          class_Name="header-bg-transparent"
        />
        <div className="container main-container d-flex justify-content-between flex-column">
          {/* <AuthHeader color="#e16e44" />
           */}

          <div className="d-flex row justify-content-center signin-form-container">
            <div className="signup-fields-container my-3 d-block col-11 col-sm-10 col-md-8 col-lg-5 text-white">
              <h1 className="signin-heading text-center">Reset Password</h1>

              <PasswordInput
                label={"New Password"}
                name={"newPassword"}
                value={newPassword}
                errors={errors}
                onChange={onChangeHandler}
              />
              <PasswordInput
                label={"Confirm New Password"}
                name={"confirmPassword"}
                value={confirmPassword}
                errors={errors}
                onChange={onChangeHandler}
              />

              <PrimaryButton
                onClick={resetHandler}
                title="SUBMIT"
                style={{
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "5px 0 12px",
                  height: "40px",
                }}
                loading={loading}
              />

              <div>
                <p className="signup-text">
                  Already Know Your Password?{" "}
                  <Link
                    to={WebRoutes.signin}
                    className="text-unselectable text-white"
                  >
                    LogIn
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2 flex-row justify-content-end align-items-center">
            <HomePageBottomIcons />
          </div>
        </div>
      </div>
    </>
  );
};
