import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HeroSection } from "../../components/HeroSection";
import Title from "../../components/Title";
import { MainHeading } from "../../components/MainHeading";
import About from "../../views/About";
import WebRoutes from "../WebRoutes";

const initialValue = {
  text1: "MEMBERSHIP-BASED ORGANIZATION",
  text2: "EMPLOYERS",
};

export const PartnerWithUsPage = () => {
  const [textShow, setText] = useState(initialValue);
  const changeText = (pos) => {
    if (pos === "left") {
      setText({ ...textShow, text1: "Learn More" });
      return;
    } else setText({ ...textShow, text2: "Learn More" });
  };
  const revertText = () => {
    setText(initialValue);
  };
  return (
    <>
      <Title title={"Trip Merchant | Partner With Us"} />

      <HeroSection
        heading={"Partner with Us"}
        title={"Enhance Your Member Benefit Program by Offering Travel"}
        buttonText={"Request Demo"}
        color={"#F25926"}
        image={require("../../assets/images/Israel-and-Jordan.jpg")}
        link={WebRoutes.Schedule}
        bannerClass={" justify-content-center text-center"}
      />
      <div className="bg-trip-primary">
        <div className="container">
          <About
            heading={"Partner with Us"}
            subheading={"HERE’S WHY:"}
            detailsArray={[
              "There are many great reasons to partner with us!",
              "Trip Merchant leverages their relationships in the global travel marketplace to bring you great travel benefits and savings in order to enhance your member benefit program.",
            ]}
            showBorder={false}
          />
          <MainHeading heading="Types of Partnership Opportunities" />

          <div className="d-flex justify-content-evenly row my-3">
            <div className="d-flex flex-column col-md-5 h-100 partner-height">
              <div
                className="col-md-12 bg-danger d-flex justify-content-center align-items-center background-cover membership-card"
                onMouseEnter={() => changeText("left")}
                onMouseLeave={revertText}
              >
                <Link
                  to={WebRoutes.membershipBasedOrganizations}
                  className="text-center m-0 font-xl font-500 text-white"
                >
                  {textShow.text1}
                </Link>
              </div>
              <Link
                to={WebRoutes.membershipBasedOrganizationsForm}
                className="hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable border-radius"
              >
                Request Demo
              </Link>
            </div>
            <div className="d-flex flex-column col-md-5 h-100 partner-height">
              <div
                className="col-md-12 bg-danger d-flex justify-content-center align-items-center background-cover employee-card"
                onMouseEnter={() => changeText("right")}
                onMouseLeave={revertText}
              >
                <Link
                  to={WebRoutes.employers}
                  className="text-center m-0 font-xl font-500 text-white"
                >
                  {textShow.text2}
                </Link>
              </div>
              <Link
                to={WebRoutes.employersForm}
                className="hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable border-radius"
              >
                Request Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
