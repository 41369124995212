import React from "react";
import { Link, useLocation } from "react-router-dom";
import WebRoutes from "../pages/WebRoutes";
const RotateButton = ({
  text = "Join Now",
  link = WebRoutes.Schedule,
  onClick,
}) => {
  const location = useLocation();
  
  if (location.pathname === '/contact-team-form') {
    link = WebRoutes.generalSubscription;
  }


  return (
    <div className="d-flex justify-content-center my-1 py-2">
      <Link
        to={onClick ? "#" : link}
        className="button-rot text-unselectable "
        onClick={onClick}
      >
        <div data-tooltip={text} />
      </Link>
    </div>
  );
};

export default RotateButton;
