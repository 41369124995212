import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputComponent } from "../../../components/Input/InputComponent";
import { FA, MD, AI, RI, BS } from "../../../common/Icons";
import { PrimaryButton } from "../../../components/Button/PrimaryButton/PrimaryButton";
import { signUpValidations } from "../../../validation";
import WebRoutes from "../../WebRoutes";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import { redirectToOrganizationDomain } from "../../../hooks/saveTokenDomain";
import { toast } from "react-hot-toast";

const iconColor = "gray";
const iconSize = "18px";
const firstNameRightIconSize = "14px";

const initialStateForm = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  organizationCode: "",
  newLetterSubscribe: true,
  acceptPolicy: true,
};

export const SignUpForm = () => {
  const [formInput, setFormInputs] = useState(initialStateForm);
  const [showPassword, setShowPassword] = useState(false);
  const [submitCalled, setSubmitCalled] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormInputs({ ...formInput, [name]: value });
    if (submitCalled) {
      setValidations({ ...formInput, [name]: value });
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const signUpHandler = async () => {
    setValidations(formInput);
    if (errors?.length) {
      return;
    }
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("first_name", formInput.firstName);
    formDataApi.append("last_name", formInput.lastName);
    formDataApi.append("email", formInput.email);
    formDataApi.append("password", formInput.password);
    formDataApi.append("password_confirmation", formInput.password);
    formInput.organizationCode.length > 0 &&
      formDataApi.append("code", formInput.organizationCode);
    formDataApi.append("is_subscribed", formInput.newLetterSubscribe ? 1 : 0);
    const response = await ApiCall.any(apiRoutes.signUpMember, {}, formDataApi);

    if (response?.status === 1) {
      if (response?.data?.email_verified) {
        redirectToOrganizationDomain(response);
        toast.dismiss();
        toast.success(response?.message);
      } else {
        toast.dismiss();
        toast.success("Please check your email to verify your account");
      }
    } /* else
      toast.error(
        response?.message || "Something went wrong, Try Again Later"
      ); */
    setLoading(false);
    setSubmitCalled(true);
  };

  const setValidations = (form) => {
    const errors = signUpValidations(form);
    setErrors(errors);
  };

  return (
    <>
      <InputComponent
        placeHolder="First Name"
        type="text"
        name="firstName"
        className={"width-100"}
        onChange={onChangeHandler}
        value={formInput?.firstName}
        iconLeft={<FA.FaUser color={iconColor} size={iconSize} />}
        iconRight={
          <BS.BsFillChatSquareDotsFill
            color={iconColor}
            size={firstNameRightIconSize}
          />
        }
        error={errors?.firstName}
      />
      <InputComponent
        placeHolder="Last Name"
        type="text"
        name="lastName"
        className={"width-100"}
        onChange={onChangeHandler}
        value={formInput?.lastName}
        iconLeft={<FA.FaUser color={iconColor} size={iconSize} />}
        error={errors?.lastName}
      />
      <InputComponent
        placeHolder="Email"
        type="email"
        name="email"
        onChange={onChangeHandler}
        className={"width-100"}
        value={formInput?.email}
        iconLeft={<MD.MdEmail color={iconColor} size={iconSize} />}
        error={errors?.email}
      />
      <InputComponent
        placeHolder="Password"
        type={!showPassword ? "password" : "text"}
        name="password"
        value={formInput?.password}
        onChange={onChangeHandler}
        iconLeft={<AI.AiFillLock color={iconColor} size={iconSize} />}
        className={"width-100"}
        iconRight={
          <div className="d-flex me-1">
            <RI.RiRotateLockFill
              color={iconColor}
              className="mx-1 icon-unselectable"
              size={iconSize}
            />
            {!showPassword ? (
              <AI.AiFillEye
                color={iconColor}
                className="mx-1 icon-unselectable"
                size={iconSize}
                onClick={togglePassword}
              />
            ) : (
              <AI.AiFillEyeInvisible
                color={iconColor}
                className="mx-1 icon-unselectable "
                size={iconSize}
                onClick={togglePassword}
              />
            )}
          </div>
        }
        error={errors?.password}
      />
      <p className="mt-3 mb-1 registered-text text-white">
        If you are already registered with an organization, type your
        organization code below.
      </p>
      <InputComponent
        placeHolder="Organization Code"
        type="text"
        name="organizationCode"
        className={"width-100"}
        onChange={onChangeHandler}
        value={formInput?.organizationCode}
        iconLeft={<MD.MdOutlineHomeWork color={iconColor} size={iconSize} />}
      />
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={true}
          value={formInput.newLetterSubscribe}
          id="signUpPolicy"
          onChange={() =>
            setFormInputs({
              ...formInput,
              newLetterSubscribe: !formInput.newLetterSubscribe,
            })
          }
        />
        <label className="form-check-label" for="signUpPolicy">
          Subscribe To Newsletter
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={true}
          value={formInput.acceptPolicy}
          id="signUpPolicy"
          onChange={() =>
            setFormInputs({
              ...formInput,
              acceptPolicy: !formInput.acceptPolicy,
            })
          }
        />
        <label className="form-check-label" for="signUpPolicy">
          By registering, you agree to our{" "}
          <Link
            to={WebRoutes.privacy}
            className="cursor-pointer hover-text text-decoration fwt-bold"
          >
            privacy policy
          </Link>{" "}
          and{" "}
          <Link
            to={WebRoutes.terms}
            className="cursor-pointer hover-text text-decoration fwt-bold"
          >
            terms and conditions
          </Link>
          .
        </label>
      </div>
      {errors && errors.acceptPolicy && (
        <small className="text-danger">{errors.acceptPolicy}</small>
      )}

      <PrimaryButton
        loading={loading}
        title="REGISTER NOW"
        onClick={signUpHandler}
      />
      <p className="registered-text text-center mt-3 text-white">
        Already have an account?{" "}
        <Link to={WebRoutes.signin} className="primary-color hover-text">
          Log In
        </Link>
      </p>
    </>
  );
};
