import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { BiDownvote } from "react-icons/bi";
import { Spinner } from "reactstrap";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import bgimg from "../../../assets/images/main-background.jpg";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Navbar/Header";
import { BorderInput } from "../../../components/Input/BorderInput/BorderInput";
import Title from "../../../components/Title";
import {
  redirectToOrganizationDomain,
  GetLogInInfo,
} from "../../../hooks/saveTokenDomain";
import { generalSubValidations } from "../../../validation";
import { BenefitList } from "./BenefitList";
import "./general-subscription-page.scss";
import { strapiUrlApi } from "../../../common/strapiUrl";
import { BenefitListStrapi } from "./BenefitListStrapi";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const dataBenefits = [
  {
    heading: "MEMBER EXCLUSIVE SAVINGS & BENEFITS.",
    description: "As a member, you save on travel.",
    detail:
      "Trip Merchant negotiates directly with travel suppliers to provide members with exclusive travel benefits and savings. Offers with savings up to 50% off!",
    //image: 12,
  },
  {
    heading: "LOYALTY PROGRAM.",
    description: "Travel more. Save more!",
    detail:
      "Earn loyalty reward dollars when you book a trip – it’s that simple. Earn up to 2% in loyalty reward dollars when booking your travel with Trip Merchant! These rewards are combinable with your member benefit & savings and other supplier loyalty reward programs. *Terms and conditions apply. ",
    image: require("../../../assets/images/WhyTripMerchant/this is (550 × 350 px) (2).png"),
  },
  {
    heading: "TRAVEL NEWSLETTERS.",
    description: "Stay up to date with the latest deals and opportunities!",
    detail:
      "Sign up for the Trip Merchant travel e-newsletter today to find out about the latest travel deals, time-sensitive offers, online presentations, trip contests, unique trips and group departures, travel tips & news and more!",
    //image: "as",
  },
  {
    heading: "TRIP MERCHANT JOURNEYS. ",
    description: "Group Departures & Private / Tailor Made.",
    detail:
      "Trip Merchant negotiates directly with travel suppliers to provide members with exclusive travel benefits and savings. Offers with savings up to 50% off!",
    image: require("../../../assets/images/WhyTripMerchant/Trip Merchant Journeys.png"),
  },
  /* {
    heading: "VIRTUAL REALITY.",
    description: "Watch it. Be inspired. Experience it.",
    detail:
      "Trip Merchant negotiates directly with travel suppliers to provide members with exclusive travel benefits and savings. Offers with savings up to 50% off!",
    image: "hh",
  }, */
  {
    heading: "TRIP ITINERARY BUILDER.",
    description: "Real-time trip planning and booking made easy. Game changer.",
    detail:
      "Do you like planning your own trips? Simply design and book your own customized trip itineraries to all your favorite travel destinations all in one easy to use booking engine!",
    /*  detail:
      "Are you an independent traveller? Do you like to coordinate your own trips? With our new platform solution, you can now simply design and book your own customized trip itineraries to all your favorite travel destinations. Choose the dates you want to travel, determine your unique trip routing, and pick from a wide selection of hotels, flights, excursions, transfers and rental cars to create/build your personalized trip itinerary. Need we say more?",
     */ //image: "as",
  },
  {
    heading: "TRIP CONTESTS.",
    description: "Become a member and enter to win travel prizes.",
    detail:
      "Want to win a complimentary trip? This is another incredible members-only benefit.",
    image: require("../../../assets/images/WhyTripMerchant/Trip Contetsts.png"),
  },
  {
    heading: "TRAVEL INSPIRATION.",
    description: "Travel tips & news - blog + videos.",
    detail:
      "Be inspired for your next trips through our many curated blogs and videos – from travel tips to important travel news. ",
    image: require("../../../assets/images/WhyTripMerchant/travelInspiration.png"),
  },
  {
    heading: "SOLO EXPLORERS.",
    description: "Where solo travelers connect.",
    detail:
      "Are your tired of paying the single supplement fees? Are you having trouble finding a travel companion? With Solo Explorers, you will receive solo travel deals, and the opportunity to be matched up with other single travelers.",
    //image: "as",
    isButton: {
      Label: "join the club.",
    },
  },
  {
    heading: "ONLINE PRESENTATIONS.",
    description: "Educational and inspirational.",
    detail:
      "Trip Merchant offers a variety of online travel presentations to watch from the comfort of your own home; learn about a specific destination, travel supplier, group departure or about Trip Merchant in general. ",
    //image: "as",
  },
  /*   {
    heading: "TRIP MERCHANT JOURNEYS.",
    description: "Group Departures & Private / Tailor Made..",
    detail:
      "Trip Merchant Journeys provides small group departures to destinations worldwide. Our Trip Merchant Journeys team of destination experts will provide you with an authentic, culturally immersive travel experience, all at an affordable price.Trip Merchant Journeys Private / Tailor Made assists in designing tailor made travel experiences to match your taste and budget, whether it be a private trip for 2, a family/friend reunion or a large group. ",
    //image: "as",
  }, */
];

export const GeneralSubscriptionPage = () => {
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    GetLogInInfo();
  }, []);
  const [formData, setFormData] = useState(initialState);
  const [data, setData] = useState([]);
  const [submitCalled, setSubmitCalled] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  async function getData() {
    const response = await fetch(
      `${strapiUrlApi}/general-subscription?populate=deep`
    );
    const data = await response.json();
    setData(data?.data?.attributes?.blocks);
    // console.log(data?.data?.attributes?.blocks, "data");
  }
  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (submitCalled) {
      setValidations({ ...formData, [name]: value });
    }
  };

  const myRef = useRef(null);
  const executeScroll = () => {
    const header = document.getElementById("headerId");
    const headerHeight = header?.offsetHeight;
    const elementTop = myRef.current?.offsetTop;
    window.scroll({
      top: elementTop - (100 + headerHeight),
    });
  };

  const generalSubHandler = async () => {
    setValidations(formData);
    if (errors?.length) {
      return;
    }
    setLoading(true);

    let formDataApi = new FormData();
    formDataApi.append("first_name", formData.firstName);
    formDataApi.append("last_name", formData.lastName);
    formDataApi.append("email", formData.email);
    formDataApi.append("password", formData.password);
    formDataApi.append("password_confirmation", formData.confirmPassword);
    formDataApi.append("is_subscribed", 0);
    formData?.code1?.length > 0 && formDataApi.append("code", formData?.code1);

    const response = await ApiCall.any(apiRoutes.signUpMember, {}, formDataApi);

    if (response?.status === 1) {
      if (response?.data?.email_verified) {
        redirectToOrganizationDomain(response);
        toast.dismiss();
        toast.success(response?.message);
      } else {
        toast.dismiss();
        toast.success("Please check your email to verify your account");
      }
      // redirectToOrganizationDomain(response);
      // toast.dismiss();
      // toast.success(response?.message);
    }
    setLoading(false);
    setSubmitCalled(true);
  };

  const setValidations = (form) => {
    const errors = generalSubValidations(form);
    setErrors(errors);
  };

  return (
    <div className="bg-black" style={{ minHeight: "100vh" }}>
      <Title title="Trip Merchant | General Subscription" />
      <div
        style={{
          backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${bgimg})`,
          backgroundSize: "100vw 100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <Header
          light={true}
          organizationWebLinks={true}
          class_Name="header-bg-transparent"
        />
        <div className="container d-flex flex-column main-container-general">
          <div className="d-flex flex-grow-1 justify-content-center mt-4">
            <div className="w-100">
              <h1 className="text-white text-center">
                BECOME A TRIP MERCHANT MEMBER TODAY!
              </h1>
              <h5 className="text-white text-center">
                NOT YOUR ORDINARY TRAVEL SITE... START YOUR FREE TRIAL BY
                REGISTERING BELOW.
              </h5>
              <div className="d-flex justify-content-center align-items-center py-2 mt-2">
                <div className="row d-flex col-md-8 card pt-2 shadow p-2 rounded bg-transparent">
                  <div className="d-flex row justify-content-between m-2 ">
                    <BorderInput
                      className="col-md-6"
                      placeHolder="First Name"
                      value={formData?.firstName}
                      onChange={inputChangeHandler}
                      name="firstName"
                      type="text"
                      error={errors?.firstName}
                    />
                    <BorderInput
                      className="col-md-6"
                      placeHolder="Last Name"
                      value={formData?.lastName}
                      onChange={inputChangeHandler}
                      name="lastName"
                      type="text"
                      error={errors?.lastName}
                    />
                    <BorderInput
                      className="col-md-12"
                      placeHolder="Email Address"
                      adjustMargin="adjust-margin"
                      value={formData?.email}
                      onChange={inputChangeHandler}
                      name="email"
                      type="email"
                      error={errors?.email}
                    />
                    <BorderInput
                      className="col-md-6 adjust-margin"
                      placeHolder="Password"
                      value={formData?.password}
                      onChange={inputChangeHandler}
                      name="password"
                      type="password"
                      error={errors?.password}
                    />
                    <BorderInput
                      className="col-md-6 adjust-margin"
                      placeHolder="Confirm Password"
                      value={formData?.confirmPassword}
                      onChange={inputChangeHandler}
                      name="confirmPassword"
                      type="password"
                      error={errors?.confirmPassword}
                    />
                    {show && (
                      <BorderInput
                        className="col-md-12 adjust-margin"
                        placeHolder="Organization Code (If you don’t have a code, contact us at info@tripmerchant.com)"
                        value={formData?.code1}
                        onChange={inputChangeHandler}
                        name="code1"
                        type="text"
                        // error={errors?.confirmPassword}
                      />
                    )}

                    <div
                      className="d-flex justify-content-end col-md-12"
                      onClick={!loading && generalSubHandler}
                    >
                      <div className="bg-cust-primary font-600 text-unselectable text-white col-md-3 input-container-border-btn my-3 hover-effect justify-content-center text-white text-center w-100 border-radius">
                        <p className="p-0 m-0">
                          {" "}
                          {loading ? <Spinner /> : "Access"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="text-white text-center font-500">
                    Belong to an organization that has a Trip Merchant
                    Partnership? If so, click{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(!show)}
                    >
                      HERE.
                    </span>
                  </p>
                  {/* <p className="text-white text-center font-500">
                    *Only new members are eligible for this offer
                  </p> */}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-1 mt-3">
                <BiDownvote
                  onClick={executeScroll}
                  className="pointer move-up-down"
                  color="#fff"
                  size={50}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={myRef} />
      <div className="bg-black">
        {/* <BenefitList dataBenefits={dataBenefits} /> */}
        <BenefitListStrapi dataBenefits={data} />
      </div>
      <Footer />
    </div>
  );
};
