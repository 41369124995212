import React from 'react';
import { Col, Row } from 'reactstrap';

export const BenefitsCards = ({ mappingData, lg="3" }) => {
  return (
    <Row lg={12} className='benefits-style-2'>
      {mappingData.map((data, index) => (
        <Col lg={lg} md={4} sm={6} key={index} className='mt-3 mb-3'>
          <div
            className='card wrapper p-3 my-3 bg-white rounded h-100 justify-content-center align-items-center'
            data-aos='fade-right'
            data-aos-duration={(1200 + (index + 1) * 100).toString()}
            data-aos-delay={(300 * (index + 1)).toString()}
            data-aos-once='true'
          >
            <div className='d-flex flex-column'>
              <div className='icon-container'>
                <div className='icon'>{data.icon}</div>
              </div>
              <div className='detail-container'>
                <div>
                  <h6 className='text-capitalize text-center'>{data.detail}</h6>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};
