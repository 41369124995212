import React/*,  { useState } */ from "react";
import { Modal, ModalBody,/*  Button, Row, Col */ } from "reactstrap";
import { Link } from "react-router-dom";
import { AI } from "../common/Icons"
import RotateButton from "./RotateButton";
import WebRoutes from "../pages/WebRoutes";
/* import apiRoutes from "../api/ApiRoutes";
import { toast } from "react-hot-toast";
import ApiCall from "../api/network"; */
import HorizontalTextLIne from "./HorizontalTextLIne";

const PopupAlert = ({ state, close, size = "md" }) => {
  /* const [email, setEmail] = useState("") */
  const handleClose = () => {
    close(false);
  };

  /*   const handleSubscribe = async () => {
      toast.dismiss();
      const formData = new FormData();
      formData.append("email", email);
      const apiResponse = await ApiCall.any(apiRoutes.subscribe, {}, formData);
      if (apiResponse.status === 1) {
        setEmail("");
        toast.success("Successfully Subscribed To NewsLetter");
      }
    } */
  return (
    <Modal isOpen={state?.show} toggle={handleClose} centered size={size} className="position-relative" /* scrollable={true} */>
      <ModalBody className="p-0 rounded">
        <img src={require("../assets/images/modal-banner.jpg")} alt="img"
          className="rounded-top"
          width={"100%"}
          height={"170px"}
        />
        <div className="modal--close-button">
          <AI.AiOutlineCloseCircle size={30} className="cursor-pointer hover-text" onClick={handleClose} />
        </div>
        <div className="p-2 container">
          <div className="d-flex justify-content-center align-items-center w-100 text-center flex-column">
            <img src={require("../assets/images/howitworks-2.png")} alt="trip merchant"
              width={"140px"}
              className="my-1"
            />
          </div>
          <div className="text center align-items-center">

            <div className="d-flex justify-content-center align-items-center w-100 text-center flex-column my-1 py-2">
              <span>Not your ordinary travel site.
              </span>
              Become a Trip Merchant Member today!
              <span className="fw-bold">Start your free trial now.</span>
              <span className="mt-1 pt-1">To view:</span>
              <span>{state?.title}</span>
            </div>
            <div className="d-flex justify-content-center">
              <RotateButton link={WebRoutes.signup} text="REGISTER HERE" />
              <HorizontalTextLIne />
              <RotateButton link={WebRoutes.generalSubscription} text="Why Trip Merchant" />
            </div>
          </div>
          <hr className="px-2 mx-4"/>
          <div className="d-flex justify-content-center align-items-center w-100 text-center flex-column gap mt-2">
            <p>Already a member? <Link to={WebRoutes.signin} className="text-primary hover-text">Log In</Link></p>
            {/*    <div className="subscribe-news-letter">
              <div className="wrapper">
                <input placeholder="subscribe to newsletter" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Button className="button-modal" onClick={() => handleSubscribe()}>Subscribe</Button>
              </div>
            </div> */}
            <p className="w-75">By using our services, you agree to abide by our  <Link to={WebRoutes.terms} className="text-primary hover-text">Terms & Conditions</Link> and <Link to={WebRoutes.privacy} className="text-primary hover-text">Privacy Policy</Link></p>
          </div>
        </div>

      </ModalBody>
    </Modal>
  );
};

export default PopupAlert;