import React, { useRef, useState, useEffect } from "react";
import About from "../../views/About";
import PartnerBanner from "../../views/PartnerBanner";
import HowItWorks from "../../views/HowItWorks";
import PartnershipBenefits from "../../views/PartnershipBenefits";
import { HeroSection } from "../../components/HeroSection";
import WebRoutes from "../WebRoutes";
import Title from "../../components/Title";
import { getData, strapiUrl } from "../../common/strapiUrl";
import JoinUs from "../../components/JoinUs";

const OrganizationHomePage = () => {
  useEffect(() => {
    getData("/home-page-tripmerchantjourneys-com").then((res) => {
      // console.log(res?.data, "res");
      setData(res?.data);
    });
  }, []);

  const [data, setData] = useState();
  const myRef = useRef(null);
  const executeScroll = (e) => {
    e && e.preventDefault();
    const elementToView = document.getElementById("HowItWorks");
    elementToView.scrollIntoView({
      offset: -250,
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  };
  return (
    <>
      <Title title="Trip Merchant | Organizations" />

      <HeroSection
        heading={
          data?.attributes?.heroTitle
            ? data?.attributes?.heroTitle
            : "Welcome to Trip Merchant"
        }
        title={
          data?.attributes?.heroDescription
            ? data?.attributes?.heroDescription
            : "Interested in Providing Exclusive Travel Benefits for Your Organization at No Cost?"
        }
        buttonText={
          data?.attributes?.heroBtnText
            ? data?.attributes?.heroBtnText
            : "How it works"
        }
        color={"#F25926"}
        image={
          data?.attributes?.heroBgImage?.data?.attributes?.url
            ? `${strapiUrl}${data?.attributes?.heroBgImage?.data?.attributes?.url}`
            : "https://tripmerchant.com/wp-content/uploads/2019/06/Boat-in-Caramoan.jpg"
        }
        onClick={executeScroll}
        link={WebRoutes.howItWorks}
        bannerClass={"justify-content-center text-center"}
      />

      <About
        heading={
          data?.attributes?.Heading
            ? data?.attributes?.Heading
            : "Who is Trip Merchant?"
        }
        subheading={null}
        textDanger={
          data?.attributes?.Description
            ? data?.attributes?.Description
            : `We partner with Membership-Based Organizations and Employers by providing them with a complimentary branded travel website dedicated to their organization.
             We partner with these organizations to enhance their member and employee benefit programs.
            We work hard to build relationships and negotiate reduced rates on their behalf with a wide variety of travel suppliers to accommodate different travel styles, such as ocean cruising, river cruising, solo travel, adventure travel, escorted tours,luxury travel and more, to provide travelers with a trip of a lifetime.
            `
        }
        isMarkdown={true}
        // showBorder={true}
      />
      <section id="partnerWithUs">
        <PartnerBanner data={data} />
      </section>
      <section ref={myRef} id="HowItWorks">
        <HowItWorks data={data} />
      </section>

      <PartnershipBenefits />

      <div
        className="background-image"
        style={{
          backgroundImage: ` url(${require("../../assets/images/Cloud_Surf.jpg")})`,
        }}
      >
        <JoinUs data={data} />
      </div>
    </>
  );
};

export default OrganizationHomePage;
