import React, { useState } from "react";
import Select from "react-select";
import { Col, Spinner } from "reactstrap";
import apiRoutes from "../../api/ApiRoutes";
import ApiCall from "../../api/network";
import { HeroSection } from "../../components/HeroSection";
import Title from "../../components/Title";
import ResponseModal from "../../components/ResponseModal";
import { RequestForms } from "../../validation";

export const EmployersFormPage = ({
  title = "Trip Merchant | Employers Form",
}) => {
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    noOfEmployees: "10-100",
    organizationName: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const onChangeInputs = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (Object.keys(errors).length > 0) {
      if (errors.hasOwnProperty(name)) delete errors[name];
    }
    return;
  };

  const onSubmit = async () => {
    const validations = RequestForms(formData);
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("first_name", formData.firstName);
    formDataApi.append("last_name", formData.lastName);
    formDataApi.append("email", formData.email);
    formDataApi.append("no_of_employees", formData.noOfEmployees);
    formDataApi.append("organization_name", formData.organizationName);
    formData.message.length > 0 &&
      formDataApi.append("message", formData.message);
    const response = await ApiCall.any(
      apiRoutes.signUpEmployee,
      {},
      formDataApi
    );
    setModalState(true);
    if (response !== -1 && response !== undefined && !response.error) {
      setLoading(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        noOfEmployees: "",
        organizationName: "",
        message: "",
      });
    }
    setLoading(false);
  };
  const selectStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: "gray",
      border: "none",
      height: "44px",
      boxShadow: "none",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "gray",
      fontWeight: "400",
    }),
  };

  return (
    <>
      <Title title={title} />

      <HeroSection
        heading="Ready to Partner with Trip Merchant?"
        title="YOUR JOURNEY STARTS HERE"
        image={require("../../assets/images/contactus.jpg")}
        bannerClass={"justify-content-center text-center"}
      />

      <div className="bg-trip-primary d-flex justify-content-center container my-3 py-2 schedule-demo">
        <Col lg={7} className="form-width-container">
          <div className="d-flex justify-content-center mb-4">
            <h3 className=" w-75 text-center font-600">
              Fill Out Form Below to Inquire About a Partnership
            </h3>
          </div>
          <hr />
          {inputData.map((input, index) => (
            <div key={index}>
              <p className="m-0 font-md font-300">{input.title}</p>
              {input.type === "text" || input.type === "email" ? (
                <>
                  <input
                    autoComplete={false}
                    className="inputs"
                    type={input.type}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />
                  {errors && errors[input.name] && (
                    <small className="text-danger">{errors[input.name]}</small>
                  )}
                </>
              ) : input.type === "select" ? (
                <Select
                  options={input.options}
                  placeholder={"Select something"}
                  clearable={false}
                  styles={selectStyles}
                  onChange={(change) => {
                    setFormData({ ...formData, noOfEmployees: change.id });
                  }}
                  defaultValue={input.options[0]}
                  className="inputs-select"
                />
              ) : (
                <>
                  {/* <small className="my-1">
                    message must be at-least 30 characters long
                  </small> */}
                  <textarea
                    autoComplete={false}
                    className="inputs"
                    style={{ height: "200px" }}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />
                  {errors && errors[input.name] && (
                    <small className="text-danger">{errors[input.name]}</small>
                  )}
                </>
              )}
            </div>
          ))}
          <div
            onClick={onSubmit}
            className="hover-effect mt-2 mb-5 py-2 bg-cust-primary text-white font-lg font-500 text-center text-unselectable border-radius"
          >
            {loading ? <Spinner /> : "Send"}
          </div>
        </Col>
      </div>
      <ResponseModal open={modalState} close={(e) => setModalState(!e)} />
    </>
  );
};

const inputData = [
  {
    name: "firstName",
    title: "First Name (required)",
    type: "text",
  },
  {
    name: "lastName",
    title: "Last Name (required)",
    type: "text",
  },
  {
    name: "email",
    title: "Email Address (required)",
    type: "email",
  },
  {
    name: "organizationName",
    title: "Organization Name (required)",
    type: "text",
  },
  {
    name: "firstName",
    title: "# of Members / Employees (required)",
    type: "select",
    options: [
      { label: "0-999", id: "0-999" },
      { label: "1000-4999", id: "1000-4999" },
      { label: "5000-9999", id: "5000-9999" },
      { label: "10000+", id: "10000+" },
    ],
  },
  {
    name: "message",
    title: "Your Message",
    type: "text area",
  },
];
