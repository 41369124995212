import React, { useEffect } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Navbar/Header";
import { useLocation, useNavigate } from "react-router-dom";
import ApiCall from "../../../api/network";

import ApiRoutes from "../../../api/ApiRoutes";

async function checkUser(uuid) {
  const response = await ApiCall.any(
    ApiRoutes.checkUser,
    {},
    {
      uuid,
    }
  );

  console.log(response, "response");
  if (response?.data) {
    return response?.data?.data;
  }
  return false;
}

const IndividualTripFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const param = queryParams.get("uuid");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (param) {
          const response = await ApiCall.any(
            ApiRoutes.checkUser,
            {},
            {
              uuid: param,
            }
          );
          response.data == true
            ? localStorage.setItem("uuid", param)
            : navigate("/");
        } else {
          const uuid = localStorage.getItem("uuid");
          if (!uuid) {
            navigate("/");
            return;
          }

          const res = await ApiCall.any(
            ApiRoutes.checkUser,
            {},
            {
              uuid,
            }
          );

          if (res?.data == false) {
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        navigate("/");
      }
    };

    fetchData();
  }, [param, navigate]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://tripmerchantcom-ibe.tripbuilder.app/integration/v2/tripmerchantcom/embed.js";
    script.type = "text/javascript";
    // debugger;
    script.onload = () => {
      // Script loaded, initialize here
      window?.nz?.init();
    };

    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* <Header
        light={true}
        organizationWebLinks={true}
        class_Name="header-bg-transparent"
      /> */}
      <div className="nezasa-style">
        <div id="nz-planner" />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default IndividualTripFilter;
