import React, { useState } from "react";
import { Row, Col, FormGroup, Input, FormFeedback } from "reactstrap";
import SocialIcons from "./SocialIcons";
import apiRoutes from "../api/ApiRoutes";
import ApiCall from "../api/network";
import { toast } from "react-hot-toast";
import { newsLetterValidation } from "../validation";

let initialState = {
  firstName: "",
  lastName: "",
  email: "",
};

const NewsLetterForm = () => {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    if (Object.keys(errors).length > 0) {
      if (errors.hasOwnProperty(name)) delete errors[name]
    }
  };

  const submitRequest = async (e) => {
    const validations = newsLetterValidation(formState);
    toast.dismiss();
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }
    e.preventDefault();
    toast.dismiss();
    const formData = new FormData();
    formData.append("email", formState.email);
    formData.append("first_name", formState.firstName);
    formData.append("last_name", formState.lastName);
    const apiResponse = await ApiCall.any(apiRoutes.subscribe, {}, formData);
    if (apiResponse.status === 1) {
      setFormState(initialState);
      toast.success("Successfully Subscribed To NewsLetter");
    }/*  else {
      toast.error("Something Went Wrong");
    } */
  };
  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Input
              type="text"
              name="firstName"
              className="input-container py-2"
              placeholder="First Name"
              onChange={onChange}
              value={formState?.firstName}
              invalid={errors?.firstName}
            />
            {errors && errors?.firstName && (
              <FormFeedback>{errors?.firstName}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Input
              type="text"
              name="lastName"
              className="input-container py-2"
              placeholder="Last Name"
              fo
              onChange={onChange}
              value={formState?.lastName}
              invalid={errors?.lastName}
            />
            {errors && errors?.lastName && (
              <FormFeedback>{errors?.lastName}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col lg="12" className="py-0">
          <FormGroup className="py-0">
            <Input
              type="text"
              name="email"
              className="input-container pt-2"
              placeholder="Email Address"
              onChange={onChange}
              value={formState?.email}
              invalid={errors?.email}
            />
            {errors && errors.email ? (
              <FormFeedback>Email is Required</FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <div className="d-flex justify-content-between py-1">
        <div>
          <button
            disabled={formState.email && formState.email.length <= 0}
            onClick={(e) => submitRequest(e)}
          >
            Submit
          </button>
        </div>
        <div className="d-flex">
          <SocialIcons />
        </div>
      </div>
    </>
  );
};

export default NewsLetterForm;
