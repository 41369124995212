import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header/Navbar/Header";
import Title from "../components/Title";
import WebRoutes from "./WebRoutes";
//update
export const NotFound404 = () => {
  return (
    <>
      <Title title={"Trip Merchant | Page not found"} />
      <Header />
      <div className="container page-not-found">
        <p className="m-4 font-md font-600">404 - PAGE NOT FOUND</p>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <p className="font-xml font-500 my-4">
            THE PAGE YOU ARE LOOKING FOR IS NOT FOUND
          </p>
          <p className="text-center w-100 px-5 mx-5 font-lg font-300">
            The page you are looking for does not exist. It may have been moved,
            or removed altogether. Perhaps you can return back to the site’s
            homepage and see if you can find what you are looking for.
          </p>

          <Link
            to={WebRoutes.home}
            className="btn-border text-unselectable hover-effect font-500"
          >
            BACK TO HOMEPAGE
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};
