import React, { useEffect, useState } from "react";
import "./sign-up-page.scss";
import { SignUpForm } from "./SignUpForm";
import { ReactComponent as WalletSvG } from "../../../assets/svg/wallet.svg";
import { ReactComponent as Group } from "../../../assets/svg/Group.svg";
import { ReactComponent as Money } from "../../../assets/svg/Money.svg";
import { ReactComponent as Support } from "../../../assets/svg/Support.svg";
import { ReactComponent as Group2 } from "../../../assets/svg/Group2.svg";
import logoWhite from "../../../assets/images/logo-full-white.png";
import { GetLogInInfo } from "../../../hooks/saveTokenDomain";
import CardWidgets from "./CardWidgets";
import { Link } from "react-router-dom";
import Title from "../../../components/Title";
import { getData } from "../../../common/strapiUrl";
/* import { BackButton } from "../../../components/Button/BackButton"; */

export const cardData = [
  {
    svg: <WalletSvG />,
    title: "Earn Loyalty Credits",
  },
  {
    svg: <Group />,
    title: "No Booking Fees",
  },
  {
    svg: <Money />,
    title: "Member Exclusive Trips",
  },
  {
    svg: <Group />,
    title: "Exclusive Benefits & Savings",
  },
  {
    svg: <Group2 />,
    title: "Trip Contests",
  },
  {
    svg: <Support />,
    title: "Expert Travel Support",
  },
];

export const SignUpPage = () => {
  useEffect(() => {
    getData("/why-trip-merchant").then((res) => {
      const newCardData = cardData.map((item, index) => {
        if (index == 0) {
          return {
            ...item,
            title: res?.data?.attributes?.EarnLoyaltyCredits,
          };
        }
        if (index == 1) {
          return {
            ...item,
            title: res?.data?.attributes?.MemberExclusiveTrips,
          };
        }
        if (index == 2) {
          return {
            ...item,
            title: res?.data?.attributes?.NoBookingFees,
          };
        }
        if (index == 3) {
          return {
            ...item,
            title: res?.data?.attributes?.ExclusiveBenefitsSavings,
          };
        }
        if (index == 4) {
          return {
            ...item,
            title: res?.data?.attributes?.TripContests,
          };
        }
        if (index == 5) {
          return {
            ...item,
            title: res?.data?.attributes?.ExpertTravelSupport,
          };
        }
      });
      setData(newCardData);
    });
  }, []);
  const [data, setData] = useState();

  useEffect(() => {
    GetLogInInfo();
  }, []);
  return (
    <div className="row signup-container m-0">
      <Title title="Trip Merchant | Register" />
      <div className="d-flex align-items-center col-lg-6 order-lg-2 form-container bg-black text-white">
        <div className="col justify-content-start width-100">
          <div className="d-flex align-items-center mb-2">
            <h2 className="signup-heading">Register</h2>
          </div>
          <p className="registered-text text-white">
            Sign up quickly to get access to great travel deals.
          </p>
          <SignUpForm />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center col-lg-6 order-lg-1 description-container">
        <div className="d-flex flex-column align-items-center justify-content-center custom-margin">
          <Link className="mb-3 mx-5" to="/">
            <img src={logoWhite} style={{ width: "220px" }} alt="" />
          </Link>
          <div className="d-flex row justify-content-center">
            {data &&
              data?.map((data, index) => (
                <CardWidgets key={index} data={data} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
