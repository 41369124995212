import React from "react";
import { IM } from "../../common/Icons";
import supplierImage from "../../assets/images/Supplier.png";
import { HeroSection } from "../../components/HeroSection";
import WebRoutes from "../WebRoutes";
import Title from "../../components/Title";
import { MainHeading } from "../../components/MainHeading";
import { Link } from "react-router-dom";
import { TravelSupplierssBenefitsCard } from "../../common/Data";
import { BenefitsCards } from "../../components/BenefitsCards";

export const TravelSuppliersPage = () => {
  return (
    <>
      <Title title="Trip Merchant | Travel Suppliers" />
      <HeroSection
        heading={"Travel Suppliers"}
        title={"Reach New Potential Customers By Partnering with Trip Merchant"}
        buttonText={"FIND OUT HOW"}
        color="#F25926"
        image={require("../../assets/images/travel.jpeg")}
        link={WebRoutes.travelSuppliersForm}
        bannerClass={" justify-content-center text-center"}
      />
      <div className="bg-trip-primary">
        <div className="container pt-3">
          <MainHeading heading="Travel Suppliers" />
          <div className="card p-3 mb-3 mx-lg-5 mt-3">
            <h4 className="text-center cust-primary-color">
              FEATURES AND BENEFITS
            </h4>
            <IM.ImAirplane
              size={40}
              className="align-self-center cust-primary-color my-3"
            />
            <p className="text-center font-600 font-lg">
              Instant exposure to hundreds of thousands of members throughout
              North America
            </p>
            <p className="text-center font-600 font-lg">
              Help to move your distressed inventory
            </p>
            <p className="text-center font-600 font-lg">
              Group Travel Opportunities
            </p>
            <p className="text-center font-600 font-lg">
              Attend Conferences and AGM’s to speak about your product
            </p>
            <p className="text-center font-600 font-lg">
              Scheduled webinars (which can be recorded and then archived on the
              travel sites)
            </p>
          </div>
          <Link to={WebRoutes.travelSuppliersForm}>
            <div className="mx-lg-5 hover-effect mx-4 my-4 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable border-radius">
              Request Demo
            </div>
          </Link>
          <MainHeading heading="Your Travel Supplier Page" />

          <p className="text-center font-300 font-lg">
            Through a partnership with Trip Merchant, you will receive your own
            customized Travel Supplier page!
          </p>
          <img className="w-100 my-4" src={supplierImage} alt="" />

          <div className="benefits ">
            <div className="container">
              <div className="my-2">
                <MainHeading heading="Travel Suppliers Benefits" />
                <div className="benefits-style-2 container my-4">
                  <BenefitsCards mappingData={TravelSupplierssBenefitsCard} />
                </div>
                <br />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
