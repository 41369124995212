import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FA, MD, AI, CG } from "../common/Icons";
import WebRoutes from "../pages/WebRoutes";

const Blog = ({ data }) => {
  const [active, setActive] = useState(false);
  return (
    <Col lg={6} className="h-100 mb-5">
      <Row lg={12}>
        <Col lg={12} className="my-1">
          <div className="wrapper">
            <img src={data.src} alt="" />
            <div className="on-hover">
              <div className="d-flex flex-column text-center">
                <h2>{data.name}</h2>
                <p>{data.position}</p>
                <small className="marker">
                  <AI.AiOutlineMinus />
                </small>
                <div className="d-flex justify-content-center my-2">
                  <a href="https://www.linkedin.com/company/trip-merchant/">
                    <FA.FaLinkedinIn className="icon mx-1" color="white" />
                  </a>
                  <Link to={WebRoutes.ContactUs}>
                    <MD.MdOutlineEmail className="icon mx-1" color="white" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} className="my-1">
          <div className="card">
            <div className="d-flex">
              <button
                className={`button
                 ${
                   active ? " non-activeColor " : ""
                 } d-flex align-items-center`}
                disabled={!active}
                onClick={() => setActive(!active)}
              >
                <FA.FaAddressBook className="me-2" size={30} />
                <h4 className="text-wrap m-0">BIO</h4>
              </button>
              <button
                className={`button
 ${!active ? " non-activeColor " : ""} d-flex align-items-center`}
                disabled={active}
                onClick={() => setActive(!active)}
              >
                <CG.CgChevronDownR className="me-2" size={30} />
                <h4 className="text-wrap m-0">READ MORE</h4>
              </button>
            </div>
            {active ? (
              <div className="content-wrapper">
                {data.Readmore.map((data, index) => (
                  <p className="font-lg" key={index}>
                    {data}
                  </p>
                ))}
              </div>
            ) : (
              <div className="content-wrapper">
                {data.Bio.map((data, index) => (
                  <p className="font-lg" key={index}>
                    {data}
                  </p>
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Blog;
