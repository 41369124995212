import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoDark from "../../../assets/images/howitworks-2.png";
import Logo from "../../../assets/images/logo.svg";
import PopupAlert from "../../../components/PopupAlert";
import WebRoutes from "../../WebRoutes";
import { strapiUrl } from "../../../common/strapiUrl";

export const BenefitListStrapi = ({ dataBenefits }) => {
  // console.log(dataBenefits, "dataBenefits");
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({
    title: null,
    show: false,
  });
  return (
    <>
      <div>
        {dataBenefits.map((element, index) => {
          {
            /* console.log(element, "element"); */
          }
          return (
            <>
              {element?.__component == "shared.image-with-heading-text" && (
                <div className="trip-merchant-journeys">
                  <div
                    key={index}
                    className={`benefit-container ${
                      element?.bgIsBlack ? "bg-black" : "bg-white "
                    } ${
                      element?.topBorder ? "benefit-container-top-border" : ""
                    }`}
                  >
                    <div
                      className={`container py-0 d-flex align-items-center row  ${
                        element?.bgIsBlack
                          ? "flex-row"
                          : "flex-row-reverse bg-white"
                      } justify-content-between`}
                    >
                      <div
                        className={`col-lg-6 d-flex ${
                          index % 2 === 0
                            ? "justify-content-lg-start"
                            : "justify-content-lg-end"
                        } justify-content-center`}
                      >
                        <div className="flex-column py-3">
                          <h2
                            style={
                              element?.HeadingLink ? { cursor: "pointer" } : {}
                            }
                            onClick={
                              element?.HeadingLink
                                ? () => navigate(element?.HeadingLink)
                                : null
                            }
                            className={` m-0 text-center text-lg-start ${
                              element?.bgIsBlack ? "text-white" : "text-dark"
                            }`}
                            data-aos="fade-up"
                            data-aos-duration="1200"
                            data-aos-once="true"
                          >
                            {dataBenefits?.length > 0
                              ? element?.Heading
                              : element?.heading}
                          </h2>

                          <p
                            className={`text-center text-lg-start mt-3 text-orange`}
                            data-aos="fade-up"
                            data-aos-duration="1600"
                            data-aos-once="true"
                          >
                            {dataBenefits?.length > 0
                              ? element?.SubHeading
                              : element?.description}
                          </p>

                          <p
                            className={`text-center text-lg-start mt-3 ${
                              element?.bgIsBlack ? "text-white" : "text-dark"
                            }`}
                            data-aos="fade-up"
                            data-aos-duration="1600"
                            data-aos-once="true"
                          >
                            {dataBenefits?.length > 0
                              ? element?.paragraph
                              : element?.detail}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`col-lg-6 d-flex py-0 ${
                          element?.bgIsBlack
                            ? "justify-content-lg-end"
                            : "justify-content-lg-start"
                        } justify-content-center`}
                      >
                        <div
                          className={`col-lg-6 w-100 d-flex py-0 ${
                            element?.bgIsBlack
                              ? "justify-content-lg-end"
                              : "justify-content-lg-start"
                          } justify-content-center`}
                        >
                          {element?.icon ? (
                            <div
                              style={
                                element?.HeadingLink
                                  ? { cursor: "pointer" }
                                  : {}
                              }
                              onClick={
                                element?.ImageLink
                                  ? () => navigate(element?.ImageLink)
                                  : null
                              }
                              className="primary--color d-flex"
                            >
                              <element.icon size={100} />
                            </div>
                          ) : element?.image ? (
                            <img
                              style={
                                element?.HeadingLink
                                  ? { cursor: "pointer" }
                                  : {}
                              }
                              onClick={
                                element?.ImageLink
                                  ? () => navigate(element?.ImageLink)
                                  : null
                              }
                              src={
                                element.image
                                  ? `${strapiUrl}${element?.image?.data?.attributes?.url}`
                                  : index % 2 === 0
                                // ? Logo
                                // : LogoDark
                              }
                              data-aos={
                                element?.bgIsBlack ? "fade-left" : "fade-right"
                              }
                              data-aos-duration="1000"
                              data-aos-once="true"
                              alt="trip merchant logo"
                              className={
                                element.isLogo
                                  ? "img-benefits"
                                  : "img-benefits-2"
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {element?.__component == "shared.image-with-heading-text" && (
                <div
                  key={index}
                  className={`benefit-container ${
                    element?.bgIsBlack
                      ? "bg-black"
                      : "bg-white position-relative"
                  } ${index === 0 ? "benefit-container-top-border" : ""}`}
                >
                  <div
                    className={`container py-0 d-flex align-items-center row ${
                      element?.bgIsBlack
                        ? "flex-row "
                        : "flex-row-reverse bg-white"
                    }  justify-content-between`}
                  >
                    <div
                      className={`col-lg-6 d-flex ${
                        element?.bgIsBlack
                          ? "justify-content-lg-start"
                          : "justify-content-lg-end"
                      } justify-content-center`}
                    >
                      <div className="flex-column py-3">
                        <h2
                          className={` m-0 text-center text-lg-start ${
                            element?.bgIsBlack ? "text-white" : "text-dark"
                          }`}
                          data-aos="fade-up"
                          data-aos-duration="1200"
                          data-aos-once="true"
                        >
                          {element?.Heading}
                        </h2>

                        <p
                          className={`text-center text-lg-start mt-3 text-orange`}
                          data-aos="fade-up"
                          data-aos-duration="1600"
                          data-aos-once="true"
                        >
                          {element?.SubHeading}
                        </p>

                        <p
                          className={`text-center text-lg-start mt-3 ${
                            element?.bgIsBlack ? "text-white" : "text-dark"
                          }`}
                          data-aos="fade-up"
                          data-aos-duration="1600"
                          data-aos-once="true"
                        >
                          {element?.paragraph}
                         
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col-lg-6 d-flex py-0 ${
                        element?.bgIsBlack
                          ? "justify-content-lg-end"
                          : "justify-content-lg-start"
                      } justify-content-center`}
                    >
                      {element?.image?.data?.attributes?.url && (
                        <img
                          style={{ width: "100%" }}
                          // src={image ? image : index % 2 === 0 ? Logo : LogoDark}
                          src={`${strapiUrl}${element?.image?.data?.attributes?.url}`}
                          data-aos={
                            element?.bgIsBlack ? "fade-left" : "fade-right"
                          }
                          data-aos-duration="1000"
                          data-aos-once="true"
                          alt="trip merchant logo"
                          className={
                            !element?.isLogo ? "img-benefits-2" : "img-benefits"
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              )} */}
              {element?.__component == "shared.call-to-action" && (
                <div className="container p-lg-5 p-2 py-3 d-flex flex-column justify-content-center align-items-center">
                  <p className="text-white text-center become-member-text col-lg-10 col-sm-12 font-500">
                    {element?.paragraph}
                  </p>
                  <button
                    onClick={() => navigate(element?.btnLink)}
                    className="p-2  px-md-5 text-white text-unselectable bg-cust-primary font-600 w-sm-100 btn-trial border-radius"
                  >
                    {element?.btnText}
                  </button>
                </div>
              )}
            </>
          );
        })}

        <div className="border-gray-bottom" />
      </div>
      <PopupAlert
        state={modalData}
        close={() =>
          setModalData({
            title: null,
            show: false,
          })
        }
      />
    </>
  );
};

/* onClick={() =>
                              setModalData({
                                title: heading,
                                show: true,
                              })
                            } */
