import React, { useState } from "react";
import "./reset-password.scss";
import { AI } from "../../../common/Icons";

const PasswordInput = ({
  value,
  name,
  placeholder,
  onChange,
  errors,
  label,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`d-flex flex-column align-self-start reset-password`}
    >
      <label>{label}</label>
      <div className="d-flex justify-content-between signin-input align-items-center">
        <input
          autoComplete={false}
          onChange={(e) => onChange(name,e.target.value)}
          value={value}
          name={name}
          placeholder={placeholder}
          type={show ? "text" : "password"}
        />
        {show ? (
          <AI.AiOutlineEyeInvisible
            size={15}
            onClick={() => setShow(false)}
            className="cursor-pointer"
          />
        ) : (
          <AI.AiOutlineEye
            size={15}
            onClick={() => setShow(true)}
            className="cursor-pointer"
          />
        )}
      </div>
      {errors? (
        <h6 className="mt-1 ms-0 text-danger error-style text-start">{errors[name]}</h6>
      ) : null}
    </div>
  );
};

export default PasswordInput;
