import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ApiCall from "../../api/network";
import ApiRoutes from "../../api/ApiRoutes";
import SkeletonCardLoader from "./SkeletonCardLoader";
import LastMinuteCards from "./LastMinuteCards";
import PopupAlert from "../../components/PopupAlert";
import WebRoutes from "../../pages/WebRoutes";
import RotateButton from "../../components/RotateButton";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

let initialValue = {
  title: null,
  show: false,
  navigate: null,
};

const FeaturedTrips = ({ text, api }) => {
  const [loading, setLoading] = useState(false);
  const [tripsData, setTripsData] = useState([]);
  const [modalData, setModalData] = useState(initialValue);

  const fetchData = async () => {
    setLoading(true);
    const response = api
      ? await ApiCall.any(ApiRoutes.generalFilter, { supplier_id: 27 })
      : await ApiCall.any(ApiRoutes.getAllTrips);

    if (response?.status === 1) {
      setTripsData(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center my-2 py-2">
        <h3 className="text-center">Trip Merchant Journeys Trips</h3>
        <div className="container py-3">
          {loading ? (
            <SkeletonCardLoader />
          ) : tripsData?.length > 0 ? (
            <Carousel
              responsive={responsive}
              autoPlay={true}
              infinite={true}
              showDots={false}
              arrows={true}
            >
              {tripsData?.map((item, index) => (
                <LastMinuteCards key={index} data={item} index={index} />
              ))}
            </Carousel>
          ) : (
            <h4 className="text-danger text-center">No Trips to Show</h4>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center px-2 mt-1">
          <RotateButton
            text=" View More Trips"
            onClick={(e) =>
              setModalData({
                title: "Trip Merchant Journeys Trips",
                show: true,
                navigate: WebRoutes.generalSubscription,
              })
            }
          />
        </div>
      </div>
      <PopupAlert state={modalData} close={() => setModalData(initialValue)} />
    </>
  );
};

export default FeaturedTrips;
