import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MembershipBenefitsCard } from '../../common/Data';
import { IM } from '../../common/Icons';
import { BenefitsCards } from '../../components/BenefitsCards';
import { HeroSection } from '../../components/HeroSection';
import { MainHeading } from '../../components/MainHeading';
import Title from '../../components/Title';
import About from '../../views/About';
import HowItWorks from '../../views/HowItWorks';
import WebRoutes from '../WebRoutes';

/* const benefits = [
  'Free Branded Travel Website',
  'Increase Engagement',
  'Exclusive Membership Savings',
  'Loyalty Program',
  'Password Protected/Members Only Access',
  'Increase Membership Size',
]; */

export const MembershipBasedOrganizations = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title title={'Trip Merchant | Membership Based Organizations'} />
      <HeroSection
        heading={'Membership-Based Organizations'}
        title='Perfect for Organizations Whose Members are Interested in Traveling and Sharing Their Own Experiences'
        buttonText={'Request Demo'}
        color={'#F25926'}
        center={true}
        link={WebRoutes.membershipBasedOrganizationsForm}
        bannerClass={' justify-content-center text-center'}
        image={require('../../assets/images/Greece-and-Turkey.jpg')}
      />
      <div className='bg-trip-primary'>
        <div className='container'>
          <About
            heading='Membership-Based Organizations'
            detailsArray={[
              'Trip Merchant provides numerous features and benefits to make your travel program a success!',
            ]}
          />
          <div className='card p-3 mb-3 mx-lg-5'>
            <h4 className='text-center cust-primary-color'>
              FEATURES AND BENEFITS
            </h4>
            <IM.ImAirplane
              size={40}
              className='align-self-center cust-primary-color my-3'
            />
            <p className='text-center font-600 font-lg'>
              Free Branded Travel Website Platform that is password protected
              for your members only access
            </p>
            <p className='text-center font-600 font-lg'>
              Enhances your value proposition by providing your members with
              exclusive membership savings that are negotiated by Trip Merchant
              with a wide variety of travel suppliers, whom represent different
              travel styles in order to accommodate many diverse interests
            </p>
            <p className='text-center font-600 font-lg'>
              Trip Merchant manages all the content on the site on your behalf
              (requiring no time commitment from any of your staff members)
            </p>
            <p className='text-center font-600 font-lg'>
              Provides an avenue of communication where members have the
              opportunity to travel with other members by organizing group trips
              and promoting them within your organization (ie an annual group
              trip)
            </p>
            <p className='text-center font-600 font-lg'>
              Members that want to organize their own group trips can also
              submit their content to us and we can add those trips to the site
            </p>
            <p className='text-center font-600 font-lg'>
              Opportunities for members that are ‘single’ to match up with other
              travel companions within your organization
            </p>
            <p className='text-center font-600 font-lg'>
              Access to Travel Suppliers to do presentations either online or at
              an event as guest speakers
            </p>
            <p className='text-center font-600 font-lg'>Free Trip Giveaways</p>
            <p className='text-center font-600 font-lg'>
              Loyalty Program to provide members with additional travel rewards
            </p>
            <p className='text-center font-600 font-lg'>
              The latest travel news to keep your members informed and up to
              date
            </p>
            <p className='text-center font-600 font-lg'>
              Videos and Travel Tips
            </p>
            <p className='text-center font-600 font-lg'>
              Build a strong sense of community and overall affinity within the
              organization
            </p>
          </div>
          <div
            onClick={() => {
              navigate(WebRoutes.membershipBasedOrganizationsForm);
            }}
            className='hover-effect mx-5 my-4 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable border-radius'
          >
            Request Demo
          </div>
          <div className='mb-5'>
            <HowItWorks heading={"Application Process"} hide={true} />
          </div>

          <div className='benefits '>
            <div className='container'>
              <div className='my-2'>
                <MainHeading heading='Membership-Based Organizations Benefits' />
                <div className='benefits-style-2 container my-4'>
                  <BenefitsCards mappingData={MembershipBenefitsCard}/>
                </div>
                <br />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
