import React from "react";
import { Spinner } from "reactstrap";
import "./primary-button.scss";

export const PrimaryButton = ({
  onClick,
  style,
  title = "SIGN UP",
  loading,
  type
}) => {
  return (
    <div
      onClick={onClick}
      className="d-flex py-1 main-button-container justify-content-center align-items-center border-radius"
      style={style ? style : {}}
      disable={loading}
      type={type}
    >
      {loading ? <Spinner /> : title}
    </div>
  );
};
