import React from "react";
import { MainHeading } from "../components/MainHeading";
import ReactMarkdown from "react-markdown";
const About = ({
  heading,
  subheading,
  detailsArray,
  showBorder,
  textDanger,
  logo,
  isAsteric,
  isMarkdown,
}) => {
  return (
    <div className="_about">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center text-center">
          <div className="my-2">
            <MainHeading heading={heading} />
            {logo ? (
              <img
                src={logo}
                className="trip-merchant-journey-logo py-2 my-2"
                alt=""
              />
            ) : null}
            {isMarkdown ? (
              textDanger && <ReactMarkdown>{textDanger}</ReactMarkdown>
            ) : textDanger ? (
              <div
                className={`danger-html ${isAsteric ? "list-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: textDanger,
                }}
              />
            ) : null}
            {subheading ? (
              <h3 className="font-xl font-300 ">{subheading}</h3>
            ) : null}
            {showBorder ? <hr /> : null}
            {detailsArray
              ? detailsArray.length > 0 &&
                detailsArray.map((data, index) => (
                  <p
                    className="font-lg font-400 text-center  px-md-5 mx-md-5"
                    key={index}
                  >
                    {data}
                  </p>
                ))
              : null}
            {/*    {isButton ? "" : null} */}
            {showBorder ? <hr /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
