import React from "react";
import { useNavigate } from "react-router-dom";

const CallToAction = ({element}) => {
  const navigate = useNavigate();
  return (
    <div className="container p-lg-5 p-2 py-3 d-flex flex-column justify-content-center align-items-center">
      <p className="text-white text-center become-member-text col-lg-10 col-sm-12 font-500">
        {element?.paragraph}
      </p>
      <button
        onClick={() => navigate(element?.btnLink)}
        className="p-2  px-md-5 text-white text-unselectable bg-cust-primary font-600 w-sm-100 btn-trial border-radius"
      >
        {element?.btnText}
      </button>
    </div>
  );
};

export default CallToAction;
