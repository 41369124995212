import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import Header from "../../../components/Header/Navbar/Header";
import Title from "../../../components/Title";
import {
  redirectToOrganizationDomain,
  GetLogInInfo,
  RemoveCookie,
} from "../../../hooks/saveTokenDomain";
import { logInValidations } from "../../../validation";
import WebRoutes from "../../WebRoutes";
import { HomePageBottomIcons } from "../HomePage/HomePageBottomIcons";
import "./sign-in.scss";

const initialStateForm = {
  email: "",
  password: "",
};

export const SignInPage = () => {
  const { search } = useLocation();
  useEffect(() => {
    if (search && search !== "" && search !== null && search !== undefined)
      RemoveCookie();
    else GetLogInInfo();
    // eslint-disable-next-line
  }, []);

  const [formInput, setFormInputs] = useState(initialStateForm);
  const [submitCalled, setSubmitCalled] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormInputs({ ...formInput, [name]: value });
    if (submitCalled) {
      setValidations({ ...formInput, [name]: value });
    }
  };

  const resendEmailVerification = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIP_MERCHANT_API_KEY}/member/email/verification-notification`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.meta.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Email resend failed. Please try again.");
      }

      const data = await response.json();
      toast.success(data?.data);
      console.log("Verification successful:", data);
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Verification failed. Please check your email and try again."
      );
      console.error("Verification failed:", error);
    }
  };
  const signInHandler = async () => {
    setValidations(formInput);
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("email", formInput.email);
    formDataApi.append("password", formInput.password);
    const response = await ApiCall.any(apiRoutes.signInMember, {}, formDataApi);
    if (response?.status === 1) {
      toast.dismiss();
      if (response?.data?.email_verified) {
        redirectToOrganizationDomain(response);
        setSubmitCalled(true);
        toast.success(response?.message);
      } else {
        setUser(response);
        setLoading(false);
        // toast.dismiss();
        // toast.error(
        //   "Please verify your email. We have sent a verification link to your email."
        // );
      }
    } /*  else
      toast.error(response?.message || "Something went wrong, Try Again Later"); */
    // setSubmitCalled(true);
    setLoading(false);
  };

  const setValidations = (form) => {
    const errors = logInValidations(form);
    setErrors(errors);
  };
  return (
    <>
      <Title title="Trip Merchant | Log In" />

      <div className="full-screen-bg" style={{ minHeight: "100vh" }}>
        <Header
          light={true}
          hide={true}
          organizationWebLinks={true}
          link={"register"}
          class_Name="header-bg-transparent"
        />
        <div className="container main-container d-flex justify-content-between flex-column">
          {/* <AuthHeader color="#e16e44" /> */}
          {user ? (
            <div>
              <div className="d-flex row justify-content-center signin-form-container">
                <div className="signup-fields-container my-3 d-block col-11 col-sm-10 col-md-8 col-lg-5 text-white">
                  <p className="signin-heading">Verify Your Email </p>
                  <p className="">
                    Please verify your email. We have sent a verification link
                    to your email.{" "}
                  </p>
                  <p className="">
                    if you did not receive an email, please click on resend
                    link!
                  </p>

                  <Button
                    className="button mb-1"
                    type="submit"
                    onClick={() => setUser(null)}
                    // disabled={loading}
                  >
                    {loading ? <Spinner /> : "Back to Log In"}
                  </Button>
                  <div className="d-flex row justify-content-between">
                    {/* <div
                  className="d-flex col-sm-6 align-items-center"
                  style={{ height: "20px" }}
                >
                  <input
                    autoComplete={false}
                    className="checkbox"
                    type="checkbox"
                    id="vehicle3"
                    name="vehicle3"
                    value="Boat"
                  />
                  <label className="checkbox-text">Remember Me</label>
                </div> */}
                    <div /* className="col-sm-6 d-flex justify-content-md-end signup-text m-0 " */
                      className="mt-1 d-flex justify-content-between"
                    >
                      {/* <Link
                        to={WebRoutes.signin}
                        className=" m-0 p-0 mt-1 text-unselectable text-white"
                      >
                        Forgot Password?
                      </Link> */}
                      {user && (
                        <div
                          onClick={() => resendEmailVerification()}
                          // to={WebRoutes.forgetPassword}
                          className=" m-0 p-0 mt-1 text-unselectable text-white"
                        >
                          Resend Email Verification?
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={signInHandler}>
              <div className="d-flex row justify-content-center signin-form-container">
                <div className="signup-fields-container my-3 d-block col-11 col-sm-10 col-md-8 col-lg-5 text-white">
                  <p className="signin-heading">Log In</p>
                  <div
                    className={`d-flex flex-column align-self-start input-error-container ${
                      errors?.email ? "mb-0" : ""
                    }`}
                  >
                    <input
                      autoComplete={false}
                      onChange={onChangeHandler}
                      value={formInput?.email}
                      className="signin-input"
                      name="email"
                      placeholder="Email"
                      type="email"
                    />
                    {errors?.email ? (
                      <h6 className="mt-1 text-danger error-style text-start">
                        {errors?.email}
                      </h6>
                    ) : null}
                  </div>
                  <div
                    className={`d-flex flex-column align-self-start input-error-container ${
                      errors?.password ? "mb-0" : ""
                    }`}
                  >
                    <input
                      autoComplete={false}
                      onChange={onChangeHandler}
                      value={formInput?.password}
                      className="signin-input"
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                    {errors?.password ? (
                      <h6 className="mt-1 pb-0 text-danger error-style text-start">
                        {errors?.password}
                      </h6>
                    ) : null}
                  </div>
                  <Button
                    className="button mb-1"
                    type="submit"
                    onClick={signInHandler}
                    disabled={loading}
                  >
                    {loading ? <Spinner /> : "Log In"}
                  </Button>
                  <div className="d-flex row justify-content-between">
                    {/* <div
                  className="d-flex col-sm-6 align-items-center"
                  style={{ height: "20px" }}
                >
                  <input
                    autoComplete={false}
                    className="checkbox"
                    type="checkbox"
                    id="vehicle3"
                    name="vehicle3"
                    value="Boat"
                  />
                  <label className="checkbox-text">Remember Me</label>
                </div> */}
                    <div /* className="col-sm-6 d-flex justify-content-md-end signup-text m-0 " */
                      className="mt-1 d-flex justify-content-between"
                    >
                      <Link
                        to={WebRoutes.forgetPassword}
                        className=" m-0 p-0 mt-1 text-unselectable text-white"
                      >
                        Forgot Password?
                      </Link>
                      {user && (
                        <div
                          onClick={() => resendEmailVerification()}
                          // to={WebRoutes.forgetPassword}
                          className=" m-0 p-0 mt-1 text-unselectable text-white"
                        >
                          Resend Email Verification?
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="signup-text">
                      New to Trip Merchant?
                      <Link
                        to={WebRoutes.signup}
                        className="text-unselectable text-white"
                      >
                        {" "}
                        Register Now
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          )}
          <div className="d-flex mt-2 flex-row justify-content-end align-items-center">
            <HomePageBottomIcons />
          </div>
        </div>
      </div>
    </>
  );
};
