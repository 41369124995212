import React from 'react';

const CardWidgets = ({ data }) => {
  return (
    <div className='d-flex justify-content-start flex-column align-items-center m-2 col-md-3 col-md'>
      <div
        data-aos='flip-left'
        data-aos-duration='700'
        data-aos-once='true'
        className='d-flex rounded-circle justify-content-center align-items-center'
        style={{
          height: '135px',
          width: '135px',
          background: 'rgba(0,0,0,0.8)',
        }}
      >
        {data.svg}
      </div>
      <p className='font-600 m-0 text-center mt-2 pt-1' style={{fontSize:"16px", whiteSpace:"nowrap"}}>{data.title} </p>
    </div>
  );
};

export default CardWidgets;
