import React from "react";
import "./border-input.scss";

export const BorderInput = ({
  col,
  placeHolder,
  name,
  type,
  value,
  onChange,
  adjustMargin,
  error,
  className,
}) => {
  return (
    <>
      <div
        className={`d-inline-flex flex-column text-white ${className} ${col} ${
          adjustMargin ? adjustMargin : ""
        }`}
      >
        <div className="input-container-border">
          <input
            autoComplete={false}
            placeholder={placeHolder}
            onChange={onChange}
            name={name}
            value={value}
            type={type}
            className="col-12 bg-transparent border-0 input-border text-white "
          />
        </div>
        {error ? (
          <h6 className="mt-lg-2 pb-0 text-danger error-style">{error}</h6>
        ) : null}
      </div>
    </>
  );
};
