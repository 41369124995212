import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { GR } from "../common/Icons";

const SuccessIcon = () => (
  <div className="d-flex justify-content-center align-items-center">
    <div className="success-icon">
      <div className="success-icon__tip"></div>
      <div className="success-icon__long"></div>
    </div>
  </div>
);

const ResponseModal = ({ open, close }) => {
  const handleClose = () => {
    close(true);
  };
  return (
    <Modal isOpen={open} toggle={handleClose} className="response-modal">
      <ModalBody /* className="modal-bg" */>
        <div className="d-flex justify-content-end">
          <GR.GrClose className="cursor-close" onClick={handleClose} />
        </div>
        <br />
        <SuccessIcon />
        <p
          className="d-flex justify-content-center text-center mt-2"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="600"
          data-aos-once="true"
        >
          Thank you for your request. We will respond to you shortly!
        </p>
        <br />
        <div className="d-flex justify-content-end">
          <Button className="response-modal-button" onClick={handleClose}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ResponseModal;
