import WebRoutes from "../pages/WebRoutes";
import { AI, BI, BS, FA, GI, HI, IO5, MD } from "./Icons";

import { ReactComponent as WalletSvG } from "../assets/svg/wallet.svg";
import { ReactComponent as Group } from "../assets/svg/Group.svg";
import { ReactComponent as Money } from "../assets/svg/Money.svg";
//import { ReactComponent as France } from "../assets/svg/France.svg";
import { ReactComponent as Support } from "../assets/svg/Support.svg";
import { ReactComponent as Exclusive } from "../assets/svg/Exclusive.svg";

export const BenefitsCard = [
  {
    icon: <GI.GiAirplaneDeparture />,
    detail: "Free Branded Travel Website",
  },
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase Engagement",
  },
  {
    icon: <MD.MdOutlineDataSaverOn />,
    detail: "Exclusive Membership Savings",
  },
  {
    icon: <MD.MdOutlineLoyalty />,
    detail: "Loyalty Program",
  },
  {
    icon: <AI.AiFillLock />,
    detail: "Password Protected/Members Only Access",
  },
  {
    icon: <HI.HiUsers />,
    detail: "Increase Membership Size/Employee Retention",
  },
  {
    icon: <GI.GiReceiveMoney />,
    detail: "Revenue Share",
  },
  {
    icon: <GI.GiTrophy />,
    detail: "Trip Contests",
  },
];

export const ProcessCard = [
  {
    src: require("../assets/images/howitworks-1.jpg"),
    heading: "SIGN UP",
    subheading: "YOUR JOURNEY STARTS HERE",
    detail:
      "We will get in touch with you within 24 hours, to explain the next steps and answer any other questions.",
  },
  {
    src: require("../assets/images/howitworks-2.png"),
    heading: "EMAIL US YOUR LOGO",
    subheading: "THIS IS WHERE WE RECEIVE YOUR LOGO",
    detail: "We will start creating your own branded travel website.",
  },
  {
    src: require("../assets/images/howitworks-3.jpg"),
    heading: "GO LIVE!",
    subheading: "WOO HOO! YOUR TRAVEL WEBSITE IS NOW LIVE!",
    detail:
      "You now have your password protected website. The site is easy to navigate and we will manage it for you entirely. We will explore and implement new features as we continue to grow our partnership together.",
  },
];

export const WebTemplate = [
  {
    src: require("../assets/images/howitworks-1.jpg"),
  },
  {
    src: require("../assets/images/howitworks-2.png"),
  },
  {
    src: require("../assets/images/howitworks-3.jpg"),
  },
  {
    src: require("../assets/images/howitworks-1.jpg"),
  },
];

export const Benefits = [
  {
    icon: <GI.GiAirplaneDeparture />,
    detail: "Free Branded Travel Websites",
  },
  {
    icon: <MD.MdOutlineGroups />,
    detail: "Member only access",
  },
  {
    icon: <MD.MdOutlineDataSaverOn />,
    detail: "Exclusive membership saving",
  },
  {
    icon: <MD.MdPersonAddAlt1 />,
    detail: "increase membership size",
  },
  {
    icon: <IO5.IoPeopleCircleOutline />,
    detail: "Increase Employee/Membership Retention",
  },
  {
    icon: <MD.MdOutlineLoyalty />,
    detail: "loyalty program",
  },
  {
    icon: <GI.GiProfit />,
    detail: "Enhance your benefits program",
  },
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase engagement",
  },
];

export const MembershipBenefitsCard = [
  {
    icon: <GI.GiAirplaneDeparture />,
    detail: "Free Branded Travel Website",
  },
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase Engagement",
  },
  {
    icon: <MD.MdOutlineDataSaverOn />,
    detail: "Exclusive Membership Savings",
  },
  {
    icon: <MD.MdOutlineLoyalty />,
    detail: "loyalty program",
  },
  {
    icon: <AI.AiFillLock />,
    detail: "Password Protected/Members Only Access",
  },
  {
    icon: <MD.MdPersonAddAlt1 />,
    detail: "Increase Membership Size",
  },

  {
    icon: <GI.GiReceiveMoney />,
    detail: "Revenue Share",
  },
  {
    icon: <GI.GiTrophy />,
    detail: "Trip Contests",
  },
];

export const employeeBenefitCard = [
  {
    icon: <GI.GiAirplaneDeparture />,
    detail: "Free Branded Travel Website",
  },
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase Engagement",
  },
  {
    icon: <MD.MdOutlineDataSaverOn />,
    detail: "Exclusive Membership Savings",
  },
  {
    icon: <MD.MdOutlineLoyalty />,
    detail: "loyalty program",
  },
  {
    icon: <AI.AiFillLock />,
    detail: "Password Protected/Members Only Access",
  },
  {
    icon: <MD.MdPersonAddAlt1 />,
    detail: "Increase Membership Size",
  },
  {
    icon: <GI.GiReceiveMoney />,
    detail: "Revenue Share",
  },
  {
    icon: <GI.GiTrophy />,
    detail: "Trip Contests",
  },
];

export const EmployerBenefitsCard = [
  {
    icon: <GI.GiAirplaneDeparture />,
    detail: "Free Branded Travel Website",
  },
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase Engagement",
  },
  {
    icon: <MD.MdOutlineDataSaverOn />,
    detail: "Exclusive Membership Savings",
  },
  {
    icon: <MD.MdOutlineLoyalty />,
    detail: "loyalty program",
  },
  {
    icon: <AI.AiFillLock />,
    detail: "Password Protected/Members Only Access",
  },
  {
    icon: <MD.MdPersonAddAlt1 />,
    detail: "Increase Membership Size",
  },

  {
    icon: <GI.GiReceiveMoney />,
    detail: "Revenue Share",
  },
  {
    icon: <GI.GiTrophy />,
    detail: "Trip Contests",
  },
];
export const TravelSupplierssBenefitsCard = [
  {
    icon: <AI.AiOutlineRise />,
    detail: "Increase Brand Awareness",
  },
  {
    icon: <FA.FaUsers />,
    detail: "Reach New Customers",
  },
  {
    icon: <GI.GiMedal />,
    detail: "Showcase Group Departures",
  },
  {
    icon: <FA.FaBoxOpen />,
    detail: "Move Distressed Inventory",
  },
  {
    icon: <BI.BiPodcast />,
    detail: "Webinar Presentation Opportunities",
  },
  {
    icon: <BS.BsNewspaper />,
    detail: "Newsletters to Communicate Special Savings",
  },
  {
    icon: <GI.GiReceiveMoney />,
    detail: "Increase Sales",
  },
  {
    icon: <GI.GiTrophy />,
    detail: "API Integration - Display All Trips",
  },
];
export const BlogContent = [
  {
    src: require("../assets/images/hq.jpg"),
    name: "TOM MACLEAN",
    position: "Co-Founder / Director of Sales & Marketing",
    Bio: [
      "Tom was born and raised on Vancouver Island. He started traveling at only a few months old and has had a passion for it ever since. He’s now traveled to every continent, except for one, Antarctica.",
      "Tom went to college and received a diploma in Marketing and Sales. After completing that course he decided to go to Cancun in 1998, and applied for various positions. Eventually securing a position in sales for a vacation club at one of the top resorts. Turns out, he had a knack for sales and that was the turning point in his career. Sales and Travel were to become his two passions.",
    ],
    linkedIn: "https://www.linkedin.com/company/trip-merchant/",
    Readmore: [
      "Tom was born and raised on Vancouver Island. He started traveling at only a few months old and has had a passion for it ever since. He’s now traveled to every continent, except for one, Antarctica.",
    ],
  },
  {
    src: require("../assets/images/ryan.jpg"),
    name: "RYAN MIKUCKI",
    position: "Co-Founder/ Director of Business Development",
    Bio: [
      "Ryan was born to travel. He has now experienced 60 countries on 6 continents, and his journeys to explore new cultures and adventures throughout the world never seem to stop.",
      "Growing up in Winnipeg, Manitoba (Canada), Ryan attended the University of Manitoba and received his Bachelor’s Degree for Recreation Management and Community Development. He also minored in Marketing. While at University he completed an internship program in Los Cabos, Mexico working at The Westin Resort as a Recreation Coordinator.",
    ],
    linkedIn: "https://www.linkedin.com/company/trip-merchant/",
    Readmore: [
      "Shortly after University, Ryan obtained a career opportunity working for a renowned global tour operator named Collette Vacations. With Collette he sold guided tours and river cruises to destinations worldwide. He had 10 successful years at Collette, as a Business Development Manager for several years before being promoted to the Director of Sales of Canada, where he managed and coached a salesforce across the country.",
      "Over the years Ryan completed numerous sales and leadership training programs, a Digital Marketing Certificate Program, as well, he attended the University of Calgary’s Continuing Education Program where he took a course on Real Estate Development Marketing, as Real Estate is another passion of his.",
      "In July 2017, he joined a travel-based technology company as the VP of Business Development where he had an immediate impact on the growth of the company. He focused on helping the travel trade grow their business by embracing technology, content and marketing through digital marketing solutions. Ryan and his business partner Tom MacLean worked closely at their previous company which has led them into launching Trip Merchant!",
      "In 2018, Ryan was also recognized as a “Top 40 Under 40” by a reputable travel trade publication called Canadian Traveller Magazine.",
      "Ryan now resides in Calgary, Alberta, Canada, just a short distance to the Rocky Mountains. On his personal time he enjoys spending time with his wife, their dog, family and  friends. He’s always up for an adventure and enjoys the outdoors!",
    ],
  },
  {
    src: require("../assets/images/IMG_3910.jpeg"),
    name: "ANELISA ANDERSON",
    position: "Retail & Group Reservation Agent",
    Bio: [
      "Anelisa was born in Edmonton and at just a day old she was adopted and hopped on a plane to Richmond, BC where she grew up. After graduating high school, she started her passion for travel by exploring South East Asia and Australia. On her return to Canada, she attended Vancouver Community College graduating with a diploma for Hospitality Management. Anelisa was then on a one way ticket to Bangkok to explore South East Asia again but this time as a solo traveler. Next stop was Europe, where she enjoyed country hopping every couple days to see and explore as much as she could.",
    ],
    linkedIn: "https://www.linkedin.com/company/trip-merchant/",
    Readmore: [
      "Eventually coming home and settling down, she secured a position in the travel industry at a river and ocean cruise company (Scenic & Emerald) in 2017. Working in the reservations department as a sales agent and then supervisor. During her time in reservations, she earned monthly top sales awards and the employee of the year award for Canada in 2019. She would eventually move to the groups department post pandemic where she gained even further knowledge in a new department.",
      "Anelisa had her baby boy in October of 2022, only to start with Trip Merchant exactly one year later on the day of her son’s birthday. Juggling being a mom, wife and not giving up on her true passion for travel and a career has been interesting but rewarding!",
    ],
  },
  {
    src: require("../assets/images/travel1.jpg"),
    name: "LYNETTE KELLY",
    position: "Retail & Group Reservation Agent",
    Bio: [
      "Lynette took Tourism and Travel in college, then applied for the role of travel agent.  She worked her way through to team leader, and lead an award winning team for many years.  Lynette’s passion for helping every client, regardless of destination, earned her the title of one of the top travel agents in her city for many years.  Her love of Luxury Caribbean travel earned Lynette a top sales award for Sandals resorts.  After a short break from the travel industry after having children, Lynette joined Trip Merchant in October 2023.",
    ],
    linkedIn: "https://www.linkedin.com/company/trip-merchant/",
    Readmore: [
      "Lynette took Tourism and Travel in college, then applied for the role of travel agent.  She worked her way through to team leader, and lead an award winning team for many years.  Lynette’s passion for helping every client, regardless of destination, earned her the title of one of the top travel agents in her city for many years.  Her love of Luxury Caribbean travel earned Lynette a top sales award for Sandals resorts.  After a short break from the travel industry after having children, Lynette joined Trip Merchant in October 2023.",
    ],
  },
  // {
  //   src: require("../assets/images/Taylor1.png"),
  //   name: "TAYLOR ",
  //   position: "Retail & Group Reservation Agent",
  //   Bio: [
  //     "Taylor has had a passion for travel and adventure from a young age.  Growing up in North Vancouver she was quite competitive with sports which lead her to travel around the US and Canada quite frequently.  She graduated from Indiana University with a Bachelor’s degree and moved around before finding herself back in North Vancouver where she worked in corporate travel for Aritzia.  She recently embarked on solo trip to Southeast Asia and fell in love with the food, culture, landscapes and amazing people.  She is excited to be a part the Trip Merchant team and to continue to explore all the beautiful countries this world has to offer!",
  //   ],
  //   linkedIn: "https://www.linkedin.com/company/trip-merchant/",
  //   Readmore: [
  //     "Taylor has had a passion for travel and adventure from a young age.  Growing up in North Vancouver she was quite competitive with sports which lead her to travel around the US and Canada quite frequently.  She graduated from Indiana University with a Bachelor’s degree and moved around before finding herself back in North Vancouver where she worked in corporate travel for Aritzia.  She recently embarked on solo trip to Southeast Asia and fell in love with the food, culture, landscapes and amazing people.  She is excited to be a part the Trip Merchant team and to continue to explore all the beautiful countries this world has to offer!",
  //   ],
  // },
];

export const NavLinksMain = [
  {
    title: "Why Trip Merchant",
    link: WebRoutes.generalSubscription,
  },
  {
    title: "Trip Merchant Journeys",
    link: WebRoutes.tripmerchantJourneys,
  },
  {
    title: "Rewards",
    link: WebRoutes.rewards,
  },
  {
    title: "Newsletter Sign Up",
    link: "#newsLetterSignUp",
  },
];

export const NavLinks = [
  {
    title: "HOME",
    anchor: false,
    link: "/home",
  },
  {
    title: "BENEFITS",
    link: WebRoutes.benefits,
    children: [
      {
        title: "HOW IT WORKS",
        link: WebRoutes.howItWorks,
      },
    ],
  },

  {
    title: "WHO WE ARE",
    link: WebRoutes.WhoWeAre,
  },
  {
    title: "PARTNER WITH US",
    link: WebRoutes.partnerWithUs,
    children: [
      {
        title: "Membership-Based Organization",
        link: WebRoutes.membershipBasedOrganizations,
      },
      {
        title: "Employers",
        link: WebRoutes.employers,
      },
    ],
  },

  {
    title: "Travel Suppliers",
    link: WebRoutes.travelSuppliers,
  },
];

export const infoSectionCards = [
  {
    heading: "Flexible Deposit",
    svg: <WalletSvG className="walletSvg" />,
  },
  {
    heading: "Group Pricing",
    svg: <Group className="groupSvg" />,
  },
  {
    heading: "Final Payment Policies",
    svg: <Money className="money" />,
  },
  {
    heading: "Exclusive Perks",
    svg: <Exclusive className="exclusive" />,
  },
  /*  {
    heading: "Travel with Like-Minded Individuals",
    svg: <France className="france" />,
  }, */
  {
    heading: "Travel with Like-Minded Individuals",
    svg: <Support className="support" />,
  },
];

export const imageBanner = [
  {
    src: require("../assets/images/01.jpg"),
  },
  {
    src: require("../assets/images/02.jpg"),
  },
  {
    src: require("../assets/images/03.jpg"),
  },
  {
    src: require("../assets/images/04.jpg"),
  },
  {
    src: require("../assets/images/05.jpg"),
  },
  {
    src: require("../assets/images/06.jpg"),
  },
];
