import React, { useState } from "react";
import { Col, Spinner } from "reactstrap";
import apiRoutes from "../../api/ApiRoutes";
import ApiCall from "../../api/network";
import { HeroSection } from "../../components/HeroSection";
import Title from "../../components/Title";
import ResponseModal from "../../components/ResponseModal";
import { RequestForms } from "../../validation";

export const TravelSuppliersForm = () => {
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    website: "",
    organizationName: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const onChangeInputs = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (Object.keys(errors).length > 0) {
      if (errors.hasOwnProperty(name)) delete errors[name];
    }
    return;
  };

  const onSubmit = async () => {
    const validations = RequestForms(formData);
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("first_name", formData.firstName);
    formDataApi.append("last_name", formData.lastName);
    formDataApi.append("email", formData.email);
    formDataApi.append("organization_name", formData.organizationName);
    formDataApi.append("website", formData.website);
    formData.message.length > 0 &&
      formDataApi.append("message", formData.message);

    const response = await ApiCall.any(
      apiRoutes.signUpTravelSupplier,
      {},
      formDataApi
    );
    if (response && response?.status === 1) {
      setModalState(true);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        organizationName: "",
        message: "",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Title title="Trip Merchant | Travel Suppliers CTA Form" />

      <HeroSection
        heading="Ready to Partner with Trip Merchant?"
        title="YOUR JOURNEY STARTS HERE"
        image={require("../../assets/images/contactus.jpg")}
        bannerClass={"justify-content-center flex-wrap text-center"}
      />

      <div className="bg-trip-primary d-flex justify-content-center container my-3 py-2 schedule-demo">
        <Col lg={7} className="form-width-container">
          <div className="d-flex justify-content-center mb-4">
            <h3 className=" w-75 text-center font-600">
              Fill Out Form Below to Inquire About a Partnership
            </h3>
          </div>
          <hr />
          {inputData.map((input, index) => (
            <div key={index}>
              <p className="m-0 font-md font-300">{input.title}</p>
              {input.type === "text" || input.type === "email" ? (
                <>
                  <input
                    autoComplete={false}
                    className="inputs"
                    type={input.type}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />
                  {errors && errors[input.name] && (
                    <small className="text-danger">{errors[input.name]}</small>
                  )}
                </>
              ) : (
                <>
                  <textarea
                    autoComplete={false}
                    className="inputs"
                    style={{ height: "200px" }}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />

                  {errors && errors[input.name] && (
                    <small className="text-danger">{errors[input.name]}</small>
                  )}
                </>
              )}
            </div>
          ))}
          <div
            onClick={loading ? null : onSubmit}
            className="hover-effect mt-2 mb-5 py-2 bg-cust-primary text-white font-lg font-500 text-center text-unselectable border-radius"
          >
            {loading ? <Spinner /> : "Send"}
          </div>
        </Col>
      </div>
      <ResponseModal open={modalState} close={(e) => setModalState(!e)} />
    </>
  );
};

const inputData = [
  {
    name: "firstName",
    title: "First Name (required)",
    type: "text",
  },
  {
    name: "lastName",
    title: "Last Name (required)",
    type: "text",
  },
  {
    name: "email",
    title: "Email Address (required)",
    type: "email",
  },
  {
    name: "organizationName",
    title: "Organization Name (required)",
    type: "text",
  },
  {
    name: "website",
    title: "Website (required)",
    type: "text",
  },

  {
    name: "message",
    title: "Your Message",
    type: "text area",
  },
];
