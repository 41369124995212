export const LoyaltyData = [
    {
        title: "How it Works",
        isTextDanger: true,
        detail: ` <p style="text-align:center">Your Trip Merchant Loyalty Account is activated as soon as you make your first booking! Once notified by us you will be able to register below where it says &ldquo;Loyalty Program Sign Up&rdquo; Once you have departed on your trip, your Travel Dollars will be added to your account automatically</p>
`
    },
    {
        title: "Earning Travel Dollars",
        isTextDanger: true,
        detail: ` <p style="text-align:center">Travel Dollars can be accumulated over time to cover the cost of an entire trip, or be applied at anytime as a credit towards a trip. The choice is yours!</p>

<p style="text-align:center">These Travel Dollars are over and above any additional Loyalty Savings that many travel suppliers may currently be offering 2% Cash Rewards on all land/cruise travel product purchased (excluding airfare/pre and post nights/taxes/port charges/insurance/Booking.com reservations).</p>

<p style="text-align:center">*You can check your loyalty balance at any time by clicking on the &ldquo;Check Loyalty Balance&rdquo; on the link below</p>

<p style="text-align:center">Any questions? Email us at loyalty@tripmerchant.com</p>`
    },
    {
        title: "Redeeming Your Travel Dollars",
        isTextDanger: true,
        detail: `<p style="text-align:center">Email: <strong>loyalty@tripmerchant.com</strong> before you make final payment with your selected Trip Merchant Travel Experience</p>

<p style="text-align:center">We require your final payment invoice, and will look after the redemption of Travel Dollars on your behalf</p>
`
    }
]

export const rewardsDataBenefits = [
    {
        heading: "Bonus Dollars",
        detail:
            `<p style="text-align:center">You can become a Trip Merchant member today for only $99 CAD/USD as an annual fee to take advantage of the many great trips, travel benefits and savings. You will then automatically receive <strong>$100 </strong>CAD/USD in Bonus Dollars to use towards a future trip.&nbsp;If you plan to book travel, then essentially there is no cost!</p>

            <p style="text-align:center">You can also start your Free 14 Day Trial without a charge. Click<em>&nbsp;</em><a href="${process.env.REACT_APP_ENV === "local" ? "http://localhost:3000/register" : "https://tripmerchantjourneys.com/register"}">HERE</a><em>&nbsp;</em>to start!</p>
            
            <p style="text-align:center">Other terms &amp; conditions: If you wish to book a trip prior to the 14 day mark, we will charge your card the $99 CAD/USD Subscription fee at time of booking. Your Bonus Dollars will not be deposited until Day 15. You will automatically earn $100 CAD/USD in Trip Merchant Bonus Dollars, which can be applied towards any travel booking; must be used prior to renewal or else it expires. Subscription renewal for all members will be 12 months for each member from their date of payment. Reminder: Whichever trip you book you will still earn 2% in Trip Merchant Loyalty on that trip (see below information) and all others going forward. Restrictions on Bonus Dollars, Referral Dollars and Loyalty Dollars will apply. Not applicable for redemption towards air only bookings, taxes/fees/visa costs/port charges/insurance, subscription renewal fees; can earn on hotel only bookings if only a minimum of a 5 night stay.</p>
            `,
    },
    {
        heading: "Referral Dollars",
        detail:
            `<p style="text-align:center">If a member refers someone to become a member of Trip Merchant, and that person subscribes to the general subscription, you will receive $25 CAD/USD in TM Loyalty Dollars!</p>
            `,
    },
    {
        heading: "Trip Merchant Loyalty Program",
        isLoyalty: true,
        detail: `<p style="text-align:center">Be rewarded for traveling with Trip Merchant!</p>

        <p style="text-align:center">Our rewards system allows you, as a Trip Merchant Member, to accumulate Travel Dollars to use for redemption on future travel experiences</p>
        
        <p style="text-align:center">Simply book travel and receive Travel Dollars</p>
        
        <p style="text-align:center">As a member, you&rsquo;re able to extend the savings to other family and friends, as long as they&rsquo;re traveling with you</p>`,
        collapseData: LoyaltyData
    },
];

export const rewardsHtmlTextDanger = `<p style="text-align:center"><span>As a member of Trip Merchant there are many incredible benefits and ways to earn reward dollars towards future travel! When you become a member of Trip Merchant, you will automatically receive <strong>Bonus Dollars</strong>  to use towards a future trip (*annual use). Also, when you refer others to use the Trip Merchant travel site, you will earn <strong>Referral Dollars</strong>. Additionally, one of the main reasons we have many loyal Trip Merchant customers today is because of our <strong>Trip Merchant Loyalty Program &ndash; Travel More. <em>Save More!</em></span></strong></p>

<p style="text-align:center"><span>What do you receive once you become a Trip Merchant member?</span></p>

<ul>
	<li style="text-align:center"><span><span><span>Access to the Trip Merchant travel site full of many amazing trips, benefits&nbsp;and savings. Visit&nbsp;</span></span></span><span><span><a href=${process.env.REACT_APP_ENV === "local" ? "http://localhost:3000/general-subscription" : "https://tripmerchantjourneys.com/general-subscription"} style="color:#1155cc" target="_blank">HERE</a><span>&nbsp;to learn more about, Why Trip Merchant.</span></span></span></li>
	<li style="text-align:center"><span><span><span>When <em>becoming a member</em> you will automatically receive $100 CAD/USD in Bonus Dollars to apply towards travel within 12 months of your subscription.</span></span></span></li>
	<li style="text-align:center"><span><span><span>When booking travel with Trip Merchant, you will earn 2% in Trip Merchant Loyalty Dollars on any booking (excluding taxes, port charges, air, and non-commissionable items, resort fees)</span></span></span></li>
	<li style="text-align:center"><span><span><span>Earn $25 CAD/USD in Bonus Dollars on any referrals of family and friends, where they become a member of Trip Merchant.</span></span></span></li>
</ul>

<p style="text-align:center"><span style="font-size:small"><span style="color:#500050"><span style="font-family:Arial,Helvetica,sans-serif"><span><span><span><span><span style="color:black">See all the reward details below to have a better understanding!</span></span></span></span></span></span></span></span></p>
` 