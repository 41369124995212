import React from "react";

const CardWidgets = ({ data, hide }) => {

  return (
    <div className="d-flex justify-content-center flex-column align-items-center text-center mt-2 pt-2">
      <div className="card-icon mb-4">{data.svg}</div>
      {
        hide
          ?
          null
          :
          <p className="font-size">{data.heading}</p>
      }
    </div>

  );
};

export default CardWidgets;
