import React from "react";
import { socialLinks } from "../../SocialMediaLinks";
import { FA, TF, MD } from "../../../common/Icons";

const icons = [
  {
    icon: <FA.FaFacebookF color="#fff" size="20px" />,
    link: socialLinks.facebook,
  },
  {
    icon: <FA.FaLinkedinIn color="#fff" size="20px" />,
    link: socialLinks.linkedin,
  },
  {
    icon: <TF.TfiYoutube color="#fff" size="20px" />,
    link: socialLinks.youtube,
  },
  { icon: <MD.MdEmail color="#fff" size="20px" />, link: socialLinks.mail },
];
export const HomePageBottomIcons = () => {
  return (
    <>
      {icons.map(({ icon, link }, index) => {
        return (
          <a
            href={link}
            key={index}
            className="icon-container d-flex justify-content-center align-items-center cursor-pointer pe-auto"
          >
            {icon}
          </a>
        );
      })}
    </>
  );
};
