import React from "react";
import { Row } from "react-bootstrap";
import { CI, IO, MD } from "../common/Icons";
import { MainHeading } from "../components/MainHeading";
import ReactMarkdown from "react-markdown";

const HowItWorks = ({ noText, hide, heading = "How it Works", data }) => {
  return (
    <div className="how-it-works">
      <div className="container">
        {!noText ? (
          <Row>
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="my-3">
                <MainHeading
                  heading={
                    data?.attributes?.howItWorks
                      ? data?.attributes?.howItWorks
                      : heading
                  }
                />
                {data?.attributes?.howItWorksDetail ? (
                  <ReactMarkdown>
                    {data?.attributes?.howItWorksDetail}
                  </ReactMarkdown>
                ) : !hide ? (
                  <>
                    <hr />
                    <p className="py-2 font-lg">
                      It’s simple! If you qualify, based on your organization,
                      then it’s as easy as providing us with your logo.
                    </p>
                    <p className="py-2 font-lg">
                      We will then upload your logo and create your own branded
                      travel website at no cost, which we will manage entirely
                      on your behalf.
                    </p>
                    <p className="py-2 font-lg">
                      Start offering your members or employees exclusive savings
                      today!
                    </p>
                    <p className="py-2 font-lg">Let’s Go Live!</p>
                  </>
                ) : null}
              </div>
            </div>
          </Row>
        ) : null}
        <Row lg={12}>
          <ul className="breadcrumbs-two">
            <li className="bread1 my-2">
              {/* eslint-disable-next-line */}
              <a
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <span>
                  <CI.CiUnlock className="icon" />
                  <strong>SignUp</strong>
                </span>
              </a>
            </li>
            <li className="bread2 my-2">
              {/* eslint-disable-next-line */}
              <a
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-once="true"
              >
                <span>
                  <IO.IoIosAdd className="icon" />
                  <strong>Email us your logo</strong>
                </span>
              </a>
            </li>
            <li className="bread3 my-2">
              {/* eslint-disable-next-line */}
              <a
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-once="true"
              >
                <span>
                  <MD.MdAirplanemodeActive className="icon" />
                  <strong>go live</strong>
                </span>
              </a>
            </li>
          </ul>
        </Row>
      </div>
    </div>
  );
};

export default HowItWorks;
