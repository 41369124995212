import React from "react";
import Title from "../../components/Title";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";

const LandingPage = ({ Component, hideAction }) => {
  return (
    <>
      <Title title={"Trip Merchant | Travel agency"} />
      <div className="wrapper">
        <div className="wrapper-inner">
          {/* <MobileHeader /> */}
          {/* <OrganizationHeader /> */}
          <Component />
          {!hideAction ? (
            <div
              className="background-image"
              style={{
                backgroundImage: ` url(${require("../../assets/images/Cloud_Surf.jpg")})`,
              }}
            >
              <JoinUs />
            </div>
          ) : null}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
