import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MainHeading } from "../components/MainHeading";
import WebRoutes from "../pages/WebRoutes";

const PartnerBanner = ({ data }) => {
  const initialValue = {
    text1: data?.attributes?.cardOneTitle
      ? data?.attributes?.cardOneTitle
      : "MEMBERSHIP-BASED ORGANIZATION",
    text2: data?.attributes?.cardTwoTitle
      ? data?.attributes?.cardTwoTitle
      : "EMPLOYERS",
    text3: data?.attributes?.cardThreeTitle
      ? data?.attributes?.cardThreeTitle
      : "TRAVEL SUPPLIERS",
  };
  const [textShow, setText] = useState(initialValue);
  const changeText = (pos) => {
    if (pos === "left") {
      setText({ ...textShow, text1: "Learn More" });
      return;
    } else if (pos === "center") setText({ ...textShow, text2: "Learn More" });
    else setText({ ...textShow, text3: "Learn More" });
  };
  const revertText = () => {
    setText(initialValue);
  };
  return (
    <div className="_partner_banner">
      <div className="container">
        <div className="d-flex justify-content-evenly row">
          <MainHeading
            heading={
              data?.attributes?.CardsHeading
                ? data?.attributes?.CardsHeading
                : "Want to Partner with Us?"
            }
          />
          <div className="d-flex flex-column col-md-4 h-100 partner-height">
            <div
              className="col-md-12 bg-danger d-flex justify-content-center align-items-center background-cover membership-card"
              onMouseEnter={() => changeText("left")}
              onMouseLeave={revertText}
            >
              <Link
                to={
                  data?.attributes?.cardOneLink
                    ? data?.attributes?.cardOneLink
                    : WebRoutes.membershipBasedOrganizations
                }
                className="text-center m-0 font-xl font-500 text-white"
              >
                {textShow.text1}
              </Link>
            </div>
            <Link
              to={
                data?.attributes?.cardOneLink
                  ? data?.attributes?.cardOneLink
                  : WebRoutes.membershipBasedOrganizations
              }
              className="hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable"
            >
              Request Demo
            </Link>
          </div>
          <div className="d-flex flex-column col-md-4 h-100 partner-height">
            <div
              className="col-md-12 bg-danger d-flex justify-content-center align-items-center background-cover employee-card"
              onMouseEnter={() => changeText("center")}
              onMouseLeave={revertText}
            >
              <Link
                to={
                  data?.attributes?.cardTwoLink
                    ? data?.attributes?.cardTwoLink
                    : WebRoutes.employers
                }
                className="text-center m-0 font-xl font-500 text-white"
              >
                {textShow.text2}
              </Link>
            </div>
            <Link
              to={
                data?.attributes?.cardTwoLink
                  ? data?.attributes?.cardTwoLink
                  : WebRoutes.employers
              }
              className="hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable"
            >
              Request Demo
            </Link>
          </div>
          <div className="d-flex flex-column col-md-4 h-100 partner-height">
            <div
              className="col-md-12 bg-danger d-flex justify-content-center align-items-center background-cover travel-card"
              onMouseEnter={() => changeText("right")}
              onMouseLeave={revertText}
            >
              <Link
                to={
                  data?.attributes?.cardThreeLink
                    ? data?.attributes?.cardThreeLink
                    : WebRoutes.travelSuppliers
                }
                className="text-center m-0 font-xl font-500 text-white"
              >
                {textShow.text3}
              </Link>
            </div>
            <Link
              to={
                data?.attributes?.cardThreeLink
                  ? data?.attributes?.cardThreeLink
                  : WebRoutes.travelSuppliers
              }
              className="hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable"
            >
              Request Demo
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerBanner;
