import React from "react";
import About from "../../views/About";
import Title from "../../components/Title";
import { Row } from "react-bootstrap";
import { BlogContent } from "../../common/Data";
import Blog from "../../components/Blog";
import { HeroSection } from "../../components/HeroSection";
import WebRoutes from "../WebRoutes";

const WhoWeAre = () => {
  return (
    <>
      <Title title={"Trip Merchant | Who We Are"} />

      <HeroSection
        heading={"Who We Are"}
        title={"Meet The Trip Merchant Team"}
        buttonText={"Contact Us"}
        color="#F25926"
        image={require("../../assets/images/whoweare.jpg")}
        link={WebRoutes.ContactUs}
        bannerClass={" justify-content-center text-center"}
      />
      <About
        heading={"Who We Are"}
        subheading={null}
        detailsArray={[
          "Trip Merchant partners with Membership-Based Organizations and Employers by providing them with a free branded travel website dedicated to their organization.",
          "We partner with these organizations to enhance their member and employee benefit programs.",
          "We have strong relationships and negotiate reduced rates on their behalf with a wide variety of travel suppliers to accommodate different travel styles and interests, such as ocean cruising, river cruising, solo travel, adventure travel, escorted tours, luxury travel and more…providing travelers with a trip of a lifetime.",
        ]}
      />
      <div className="d-flex justify-content-center">
        <h2>Meet the Team</h2>
      </div>
      <div className="container who-we-are">
        <Row className="mx-lg-5">
          {BlogContent.map((data, index) => (
            <Blog key={index} data={data} />
          ))}
        </Row>
      </div>
    </>
  );
};

export default WhoWeAre;
