import React from "react";
import { useLocation } from "react-router-dom";
import { NavLinks, NavLinksMain } from "../../../common/Data";
import { Link } from "react-router-dom";
import WebRoutes from "../../../pages/WebRoutes";


const NavBar = ({ organizationWebLinks }) => {
  const executeScroll = (e, id) => {
    e && e.preventDefault();
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView({
      offset: -250,
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  };
  const { pathname } = useLocation()
  return (
    <>
      <nav className="navbar d-lg-block d-md-none py-0">
        <ul className="d-flex justify-content-center align-items-center parent-list p-0">
          {organizationWebLinks
            ? NavLinksMain.map((data, index) =>

              <li
                style={{
                  textTransform: "uppercase",
                  borderInlineEnd:
                    index + 1 !== NavLinksMain.length
                      ? "2px solid #e16e44"
                      : "none",
                  textShadow: "0px 1px 1px #000000",
                }}
                className=" font-500"
                key={index} u
              >
                {
                  data.link === "#newsLetterSignUp" ?
                    // eslint-disable-next-line 
                    <a onClick={(e) => executeScroll(e, "newsLetterSignUp")}>
                      {data.title}
                    </a> : <Link to={data.link} className="text-white ">
                      {data.title}
                    </Link>
                }

              </li>

            )
            : NavLinks.map((data, index) => (
              <li
                style={{
                  textTransform: "uppercase",
                  borderInlineEnd:
                    index + 1 !== NavLinks.length
                      ? "2px solid #e16e44"
                      : "none",
                  textShadow: "0px 1px 1px #000000",
                }}
                className=" font-600"
                key={index}
              >
                {
                  data?.title === "PARTNER WITH US" && pathname === WebRoutes.tripmerchant
                    ?
                    // eslint-disable-next-line
                    <a onClick={(e) => executeScroll(e, "partnerWithUs")}>
                      {data.title}
                    </a>
                    :
                    <Link to={data.link} className="text-white" target={data?.anchor && "_blank"}>
                      {data.title}
                    </Link>
                }

                {data?.children ? (
                  <ul className="child-list">
                    {data?.children?.map((dataChild, index) => (

                      <li
                        style={{
                          textTransform: "uppercase",
                        }}
                        className=" font-600"
                        key={index}
                      >
                        <Link to={dataChild.link}>{dataChild.title}</Link>
                      </li>
                    ))}
                  </ul>
                ) : null}

              </li>
            ))}
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
