import React from "react";
import { HomePageCards } from "./HomePageCards";
import "./home-page.scss";
import WebRoutes from "../../WebRoutes";

import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Navbar/Header";
import Title from "../../../components/Title";

const data = [
  {
    textLabel: "Already a Trip Merchant member? Log in here.",
    buttonLabel: "LOG IN",
    link: WebRoutes.signin,
  },
  {
    textLabel: "Want to be a Trip Merchant member? Register here.",
    buttonLabel: "REGISTER",
    link: WebRoutes.generalSubscription,
  },
  {
    textLabel: "Interested in partnering with us? Learn more here.",
    buttonLabel: "CLICK HERE",
    anchor:true,
    link: WebRoutes.tripmerchant,
  },
];

export const HomePage = () => {
  return (
    <div
      className="d-flex flex-column align-items-between justify-content-between"
      style={{ minHeight: "100vh" }}
    >
      <Title title="Trip Merchant | Travel Company" />
      <div className="bg-black d-flex flex-column justify-content-center align-items-between full-screen-bg">
        <Header
           light={true}
           organizationWebLinks={true}
           class_Name="header-bg-transparent"
        />
        <div className="container main-container d-flex flex-column">
          <div className="row d-flex cards-container justify-content-center align-items-center">
            {data.map((item, index) => {
              return <HomePageCards data={item} key={index} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
