import { create } from 'apisauce'

export const config = async () => {
  return {
    headers: {
      Accept: 'application/json',
    },
  }
}

export const multiPartConfig = async () => {
  return {
    headers: {
      Accept: 'application/json',
      // Accept: 'multipart/form-data',
    },
  }
}

export const apiClient = create({
  baseURL: process.env.REACT_APP_TRIP_MERCHANT_API_KEY,
})
