import React, { useState } from "react";
import { Collapse, CardBody, Card, CardTitle } from "reactstrap";
import { RX } from "../common/Icons";
import ReactMarkdown from "react-markdown";

const CollapsedMenuStrapi = ({
  data,
  className,
  centered,
  font = "fw-normal",
  setActive,
  active,
  isblack,
}) => {
  const [show, setShow] = useState();
  return (
    <Card className={`${className ? className : ""}`}>
      <CardTitle
        className="p-2 mb-0 bg-background-primary d-flex justify-content-between align-items-center"
        onClick={() =>
          setActive
            ? setActive(active === data?.Title ? "" : data?.Title)
            : setShow(!show)
        }
      >
        {centered && <p></p>}
        <p className={`mb-0 ${font}`}>{data?.Title}</p>
        {show ? <RX.RxCaretUp size={30} /> : <RX.RxCaretDown size={30} />}
      </CardTitle>
      <Collapse isOpen={active === data?.Title || show}>
        <CardBody>
          {data?.detail && (
            <span style={isblack ? { color: "white" } : { color: "black" }}>
              <ReactMarkdown className={`danger-html`}>
                {data?.detail}
              </ReactMarkdown>
            </span>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default CollapsedMenuStrapi;
