import React from "react";
import BannerContainer from "./BannerContainer";
import Header from "./Header/Navbar/Header";

export const HeroSection = ({
  heading,
  title,
  buttonText,
  color,
  image,
  center,
  onClick,
  bannerClass,
  link,
  buttonClass,
  howItWorks,
  minHeight,
  Tag,
  organizationWebLinks = false
}) => {
  const imgPath = `url(${image})`;
  return (
    <div
      className={`${howItWorks ? "bg-how-it-works" : "background-image"
        } d-flex flex-column`}
      style={{
        backgroundImage: imgPath,
        backgroundPosition: center ? "center" : "top-left",
        minHeight: minHeight ? minHeight : "100%",
        backgroundSize: "cover",
        objectFit: "fill",
        backgroundAttachment: "fixed",
      }}
    >
      <Header class_Name="header-bg-transparent" organizationWebLinks={organizationWebLinks} />
      <BannerContainer
        {...{
          heading,
          title,
          buttonText,
          color,
          bannerClass,
          onClick,
          link,
          buttonClass,
          howItWorks,
          Tag
        }}
      />
    </div>
  );
};
