import React, { useState, useEffect } from 'react'
import Title from '../../../components/Title'
import { HeroSection } from "../../../components/HeroSection"
import PopupAlert from '../../../components/PopupAlert'
import About from '../../../views/About'
import WebRoutes from '../../WebRoutes'
import { BenefitList } from "./BenefitList";
import "./general-subscription-page.scss";

export const SoloTravelerContent =
    `<p style="text-align:justify"><strong>Solo Explorers </strong>is <strong>Trip Merchant&rsquo;s Solo Travel Club</strong>. As part of &ldquo;<strong>Solo Explorers</strong>&rdquo; you will find the best solo deals available that are either greatly reduced or where the single supplements are waived. Trip Merchant also offers a matching program on all Trip Merchant Journeys group departures, where solo travellers of the same gender are matched up with other solo travellers that are looking for a travel companion.</p>

    <p style="text-align:justify">Are you tired of paying the single supplement fees? Do you want to stay in your own room, while having the comfort of being with a group of people? Do you want to find a travel companion?</p>
    
    <p style="text-align:justify">Solo Travel is one of the fastest-growing travel trends today. Embarking on a solo trip is a liberating experience. Solo Travel does not necessarily mean you will be exploring a destination alone! It allows you to meet many new people along your journey. Vacationing as a solo traveler will give you a completely new travel perspective and a strong sense of comfort and security.</p>
    
    <p style="text-align:justify">Subscribe now to the Trip Merchant Solo Explorers newsletter to ensure you don&rsquo;t miss out on various trip opportunities! This newsletter will be sent out at the beginning of every month.</p>
    
    <p style="text-align:justify">Have the subscription form for this newsletter.</p>
    
    <p>&nbsp;</p>
    
    <p>&nbsp;</p>`
let initialValue = {
    title: null,
    show: false,
    navigate: null,
}

const dataBenefits = [
    {
        heading: "Single Supplements Reduced/Waived.",
        description: "As a member, you save on travel.",
        detail:
            "Trip Merchant negotiates directly with travel suppliers to provide members with exclusive travel benefits and savings. Offers with savings up to 50% off!",
        /* image: "as", */
    },
    {
        heading: "Find a Travel Companion.",
        description: "Travel more. Save more!",
        detail:
            "Earn loyalty reward dollars when you book a trip – it’s that simple. Earn up to 2% in loyalty reward dollars when booking your travel with Trip Merchant! These rewards are combinable with your member benefit & savings and other supplier loyalty reward programs. *Terms and conditions apply. ",
        /* image: "as", */
    },
    {
        heading: "Make New Friends.",
        description: "Stay up to date with the latest deals and opportunities!",
        detail:
            "Sign up for the Trip Merchant travel e-newsletter today to find out about the latest travel deals, time-sensitive offers, online presentations, trip contests, unique trips and group departures, travel tips & news and more!",
        /* image: "as", */
    },
    {
        heading: "Comfort & Security.",
        description: "Private / Tailor Made & Group Departures.",
        detail:
            "Trip Merchant negotiates directly with travel suppliers to provide members with exclusive travel benefits and savings. Offers with savings up to 50% off!",
        /* image: "as", */
    },
    {
        heading: "Freedom to Explore.",
        description: "Real-time trip planning and booking made easy. Game changer.",
        detail:
            "Are you an independent traveller? Do you like to coordinate your own trips? With our new platform solution, you can now simply design and book your own customized trip itineraries to all your favorite travel destinations. Choose the dates you want to travel, determine your unique trip routing, and pick from a wide selection of hotels, flights, excursions, transfers and rental cars to create/build your personalized trip itinerary. Need we say more?",
        /* image: "as", */
    },
];

export const imageBanner = [
    {
        src: require("../../../assets/images/01.jpg"),
    },
    {
        src: require("../../../assets/images/02.jpg"),
    },
    {
        src: require("../../../assets/images/03.jpg"),
    },
    {
        src: require("../../../assets/images/04.jpg"),
    },
    {
        src: require("../../../assets/images/05.jpg"),
    },
    {
        src: require("../../../assets/images/06.jpg"),
    },
];

const SoloTraveler = () => {
    const [random, setRandom] = useState(Math.floor(Math.random() * imageBanner.length))
    const [modalData, setModalData] = useState(initialValue)

    const showModal = () => {
        setTimeout(() => setModalData({
            title: "Solo Explorers",
            show: true,
            navigate: WebRoutes.generalSubscription,
        }), 10000);

    }

    useEffect(() => {
        showModal()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => setRandom(Math.floor(Math.random() * imageBanner.length)), 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className='position-relative'>
            <Title title="Trip Merchant | Solo Explorers" />
            <HeroSection
                light={true}
                organizationWebLinks={true}
                heading={"Solo Explorers"}
                image={imageBanner[random].src}
                bannerClass={"justify-content-center text-center"}
            />
            <About
                heading={"Why Solo Explorers"}
                textDanger={SoloTravelerContent}
                showBorder={false}
            />
            <div className="bg-black">
                <BenefitList dataBenefits={dataBenefits} />
            </div>
            <PopupAlert
                state={modalData}
                close={() =>
                    setModalData(initialValue)
                }
                size={"sm"}
            />
        </div>
    )
}

export default SoloTraveler