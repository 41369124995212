import React, { Fragment } from "react";
import About from "../../views/About";
import Title from "../../components/Title";
import { Row, Col } from "react-bootstrap";
import { FA } from "../../common/Icons";
import { ProcessCard, WebTemplate } from "../../common/Data";
import WebRoutes from "../WebRoutes";
import { Link } from "react-router-dom";
import { HeroSection } from "../../components/HeroSection";

const HowItWorks = () => {
  return (
    <>
      <Title title={"Trip Merchant | How It Works"} />
      <HeroSection
        heading={"How it Works"}
        title={null}
        buttonText={"Request Demo"}
        color={"#e16e44"}
        bannerClass={"text-md-end text-center w-md-75"}
        buttonClass={"justify-content-md-end justify-content-center me-md-5"}
        image={require("../../assets/images/howitworks.jpg")}
        link={WebRoutes.Schedule}
        howItWorks={true}
        minHeight="200px"
      />
      <About
        heading={"How it Works"}
        subheading={null}
        detailsArray={[
          "It’s simple! If you qualify based on your organization, then it’s as easy as providing us with your logo.",
          "We will then upload your logo and create your own branded travel website at no cost, which we will manage entirely on your behalf.",
          "Start offering your members or employees exclusive savings today!",
          "Let’s Go Live!",
        ]}
      />
      <hr className="container hr" />
      <About
        heading={"The Process"}
        subheading={null}
        detailsArray={[]}
        showBorder={false}
      />
      <div className="container how-it-works-style-2">
        <Row lg={12}>
          {ProcessCard.map((data, index) => (
            <Fragment key={index}>
              <Col lg={6} sm={6} className="border py-2">
                <span className="circle">
                  <FA.FaCircle />
                </span>
                <div
                  className="d-flex justify-content-center"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-once="true"
                >
                  <div className="image">
                    <img src={data.src} alt="" />
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={6} className="py-2">
                <div
                  className="container"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  data-aos-once="true"
                >
                  <div className="d-flex flex-column justify-content-center wrapper">
                    <h3>{data.heading}</h3>
                    <small>{data.subheading}</small>
                    <p className="detail">{data.detail}</p>
                  </div>
                </div>
              </Col>
            </Fragment>
          ))}
        </Row>
      </div>
      <div className="d-flex justify-content-center how-it-works-style-2 my-3 container">
        <Link
          className="mx-5 col-12 hover-effect my-2 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable w-75 border-radius"
          to={WebRoutes.Schedule}
        >
          Application Form
        </Link>
      </div>
      <div className="container">
        <hr />
      </div>
      <About
        heading={"Website Design"}
        subheading={null}
        detailsArray={[
          "We will create a beautiful, modern, and professional travel website for you. This will be branded with your own logo and color scheme to match it.",
        ]}
        showBorder={false}
      />
      <div className="container how-it-works-style-2">
        <Row className="my-2">
          {WebTemplate.map((data, index) => (
            <Col lg={6} sm={6} className="py-2" key={index}>
              <div
                className="d-flex justify-content-center"
                data-aos={index % 2 !== 0 ? "fade-left" : "fade-right"}
                data-aos-duration="1200"
                data-aos-once="true"
              >
                <div className="image">
                  <img src={data.src} alt="" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default HowItWorks;
