// eslint-disable-next-line
export default {
  home: "/",
  signup: "/register",
  signin: "/login",
  forgetPassword: "/forget-password",
  generalSubscription: "/general-subscription",
  tripmerchant: "/home",
  benefits: "/benefits",
  howItWorks: "/how-it-works",
  travelSuppliers: "/travel-suppliers",
  travelSuppliersForm: "/travel-suppliers-form",
  WhoWeAre: "/who-we-are",
  Schedule: "/schedule-demo",
  membershipBasedOrganizations: "/membership-based-organizations",
  membershipBasedOrganizationsForm: "/membership-based-organization-form",
  employers: "/employers",
  employersForm: "/employers-form",
  partnerWithUs: "/partner-with-us",
  ContactUs: "/contact-team-form",
  resetPassword: "/reset-password/:id/:email/:role",
  faq: "/faq",
  testimonials: "/testimonials",
  privacy: "/privacy-policy",
  terms: "/terms-of-use",
  tripmerchantJourneys: "/tripmerchant-journeys",
  soloTraveler: "/solo-traveler",
  rewards: "/rewards-program",
  individual: "/ibe",
  verificationEmail: "/email/verify/:id/:token",
};
