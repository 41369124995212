import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import Title from "../../../components/Title";

import "./sign-in.scss";
import WebRoutes from "../../WebRoutes";

export const VerificationEmail = () => {
  const navigate = useNavigate();
  const { id, token } = useParams();
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);
  const expires = queryParams.get("expires");
  const signature = queryParams.get("signature");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id && token) {
      if (expires && signature) {
        if (isVerified) {
          console.log(isVerified);
        } else verifyEmail();
      }
    }
  }, []);

  const verifyEmail = async () => {
    setIsVerified(true);
    const response = await ApiCall.any(apiRoutes.verification(id, token), {
      expires,
      signature,
    });
    if (response?.status === 1) {
      setLoading(false);
    } else
      toast.error(response?.message || "Something went wrong, Try Again Later");
  };

  return (
    <>
      <Title title="Trip Merchant | Verification Email" />

      <div className="full-screen-bg" style={{ minHeight: "100vh" }}>
        <div className="container main-container d-flex justify-content-between flex-column">
          <div className="d-flex row justify-content-center signin-form-container1">
            <div className="signup-fields-container1 my-3 d-block col-11 col-sm-10 col-md-8 col-lg-5 text-white">
              <p
                className="signin-heading1"
                style={{ textAlign: "center", fontSize: "30px " }}
              >
                Your Email has been {loading ? <Spinner /> : "verified"}
              </p>
              <Button
                className="button mb-1"
                type="submit"
                onClick={() => {
                  {
                    navigate(WebRoutes.signin);
                  }
                }}
              >
                {"Back to Log In"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
