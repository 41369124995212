import React, { useState } from "react";
import { MD } from "../../common/Icons";
import PopupAlert from "../../components/PopupAlert";
import { Button } from "reactstrap";
import WebRoutes from "../../pages/WebRoutes";

let initialValue = {
  title: null,
  show: false,
  navigate: null,
};

const LastMinuteCards = ({ data, index, col }) => {
  const [modalData, setModalData] = useState(initialValue);
  const handleNavigate = (e) => {
    e.preventDefault();
    //navigate(`${WebRoutes}/${data?.uuid}`)
    setModalData({
      title: "Trip Merchant Journeys Trips",
      show: true,
      navigate: WebRoutes.generalSubscription,
    });
  };
  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <>
      <div
        lg="3"
        md="6"
        className={`last-deals-card ${col ? col : ""} py-2 mt-1 cursor-pointer`}
        onClick={(e) => handleNavigate(e)}
      >
        <div className="deals-card m-1 p-1">
          <div
            className="deals--card rounded"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52.4%, #000000 100%)
            , url(${
              data?.image?.file_url ||
              data?.image ||
              require("../../assets/images/Cloud_Surf.jpg")
            })`,
            }}
          >
            {/* <div
              className={`d-flex ${
                data?.departure_dates?.length > 0
                  ? " justify-content-between"
                  : "justify-content-end"
              } p-2`}
            >
              {data?.departure_dates?.length > 0 && (
                <div className="deals-icon-bg px-2">
                  <MD.MdOutlineLocalOffer />
                  <span className="ms-1">
                    C$ {data.departure_dates[0].price}
                  </span>
                </div>
              )}
              <div className="deals-icon-bg px-2">
                {" "}
                {data?.total_nights} Nights
              </div>
            </div> */}
            <div
              className={`d-flex ${
                data?.departure_dates?.length > 0
                  ? " justify-content-between align-items-center"
                  : "justify-content-end"
              }  p-2`}
            >
              {data?.departure_dates?.length > 0 && (
                <div className="deals-icon-bg px-2">
                  <MD.MdOutlineLocalOffer color="primary-color" />
                  {data?.departure_dates[0]?.discounting_member_price ? (
                    <div className="d-flex flex-column py-1 my-1">
                      <p
                        className={`price ms-1 ${
                          data?.departure_dates[0].discounting_member_price
                            ? "text-success"
                            : ""
                        }`}
                      >
                        {/* <DisplayPrice
                          price={
                            data?.departure_dates[0]?.discounting_member_price
                          }
                        /> */}
                        {data?.departure_dates[0].price}
                      </p>
                      <small
                        className={`text-danger ms-1 ${
                          data?.departure_dates[0].discounting_member_price
                            ? "strike-through"
                            : ""
                        }`}
                      >
                        {/* <DisplayPrice price={data?.departure_dates[0].price} /> */}
                        {data?.departure_dates[0].price}
                      </small>
                    </div>
                  ) : (
                    <p className={`price ms-1`}>
                      {/* <DisplayPrice price={data?.departure_dates[0].price} /> */}
                      {data?.departure_dates[0].price}
                    </p>
                  )}
                </div>
              )}
              {data?.total_nights ? (
                <div className="deals-icon-bg px-2">
                  {" "}
                  {data?.total_nights} Nights
                </div>
              ) : null}
            </div>
            <div className="d-flex text-position width-100 blur-filter-overlay text-justify">
              <div className="d-flex justify-content-between">
                <p className="title">{data?.title || "Tripe Title"}</p>
                <div
                  className="p-2 hover-overlay cursor-pointer"
                  onClick={(e) => handleFavorite(e)}
                >
                  <MD.MdFavoriteBorder size={20} />
                </div>
              </div>
              <div
                // style={{ fontSize: "14px" }}
                className="d-flex justify-content-between align-items-center px-1 mt-1"
              >
                <Button
                  className="bg-background-primary hover-effect"
                  onClick={(e) => handleNavigate(e)}
                >
                  View Details
                </Button>
                {data?.special_offer && (
                  <button class="button-33" role="button">
                    {data?.special_offer
                      ? data?.special_offer
                      : "Special Offer"}
                  </button>
                )}

                {/*  {index % 2 === 0 ? (
                <div className="rating-style-2">
                  <Rate disabled max={5} size="xs" defaultValue={4} allowHalf />
                </div>
              ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupAlert state={modalData} close={() => setModalData(initialValue)} />
    </>
  );
};

export default LastMinuteCards;
