import React, { useState, useEffect, useRef } from "react";
import Title from "../../../components/Title";
import { HeroSection } from "../../../components/HeroSection";
import PopupAlert from "../../../components/PopupAlert";
import About from "../../../views/About";
import WebRoutes from "../../WebRoutes";
import { BenefitList } from "./BenefitList";
import InfoSection from "../../../components/infoSection/InfoSection";
import FeaturedTrips from "../../../views/trips/FeaturedTrips";
import WarningBanner from "../../../components/WarningBanner";
import Testimonials from "../../../views/Testimonials";
import { imageBanner } from "../../../common/Data";
import "./general-subscription-page.scss";
import { getData, strapiUrl } from "../../../common/strapiUrl";
import { BenefitListStrapi } from "./BenefitListStrapi";

export const tripMerchantJourneysContent = `<p style="text-align:center">Travel, on another level&hellip;Trip Merchant Journeys&nbsp;is&nbsp;Trip Merchant&rsquo;s&nbsp;tour operator brand, which delivers unique travel experiences throughout the world. Whether you travel on a set Group Departure, or as a Private/Tailor Made trip, you will see destinations through a different lens.&nbsp;</p>

    <p style="text-align:center">Trip Merchant Journeys Group Departures&nbsp;focus on small groups, where you travel with other like-minded travellers. The group sizes are 16-24 passengers only. These trips, whether a tour or cruise or a combination of both, are diligently designed and tested programs. On a Trip Merchant Journeys experience, you will travel off the beaten track, while staying in great quality hotels that are centrally, or strategically, located to the areas you are visiting. The itineraries include most of the meals, cultural immersion experiences, the majority of the tours/activities, gratuities, and top expert local guides/drivers.</p>
    
    <p style="text-align:center">These perfectly packaged group trip itineraries ensure you come back wanting to travel to more incredible countries worldwide!&nbsp;Trip Merchant Journeys&nbsp;provides many trips to many countries and are continually adding new destinations and departure dates throughout the year. Trip Merchant Journeys Group Departures are hosted by a Trip Merchant representative or local guide/tour managers in the destination,</p>
    
    <p style="text-align:center"><em>Destinations</em>&nbsp;include&nbsp;Egypt, Jordan, South Africa, Zimbabwe, Botswana, Portugal, Colombia, Ecuador, Antarctica, Iceland, Costa Rica, Eastern Europe &ndash; Romania, Vietnam, Cambodia, Morocco, and more!</p>
    `;
let initialValue = {
  title: null,
  show: false,
  navigate: null,
};

const dataBenefits = [
  {
    heading: "Small Group Travel.",
    description: "Only 16-24 passengers!",
    detail: `Trip Merchant Journeys believes that small group travel provides a
            better overall experience. You can participate in many more culturally immersive
            activities, stay in boutique accommodations, and dine in unique restaurants. Smaller
            group travel encourages more friendships and connections to be made, which helps
            make the group cohesive`,
    image: require("../../../assets/images/tripMerchantJourneys/Small groups 1.png"),
  },
  {
    heading: "Hand-Picked Hotels.",
    description: "Unique hotels. Conveniently located.",
    detail: `On all Trip Merchant Journeys programs, you will stay at hotels that are
            in excellent locations. Whether it be in the heart of Reykjavik in Iceland or relaxing at a
            resort along the Red Sea in Egypt. TMJ selects accommodations based on personal
            experience, local recommendations, customer feedback/surveys, while considering the
            location, authenticity, and amenities available`,
    image: require("../../../assets/images/tripMerchantJourneys/Hand-picked hotels.png"),
  },
  {
    heading: "Memorable Dining Experiences.",
    description: "Many meals. And…be WOW’D.",
    detail: ` Trip Merchant Journeys selects dining experiences that capture the
            flavour of the destination being visited. Many meals are included, with a mix of local
            restaurants to top quality hotel restaurants. Welcome dinners are a great introduction,
            and farewell dinners are an exciting experience for all TMJ guests – whether eating in
            Dracula’s castle in Romania to witnessing the amazing sunset on a rooftop overlooking
            the ocean in Costa Rica`,
    image: require("../../../assets/images/tripMerchantJourneys/Memorable Dining Experiences.png"),
  },
  {
    heading: "Inclusive Trips.",
    description: "Experience more.",
    detail: `You will enjoy inclusive itineraries that are full of many cultural tours &
            activities. Although you have a lot included, there will always be built in leisure time to
            allow for independent exploration. And, depending on the destination, Trip Merchant
            Journeys often ends their programs at a beach destination for some rest and relaxation,
            or offers a post extension for further discovery`,
    image: require("../../../assets/images/tripMerchantJourneys/Inclusive Trips.png"),
  },
  {
    heading: "Included Gratuities.",
    description: "Take the stress out of tipping.",
    detail: `We include tipping on all Trip Merchant Journeys departures, eliminating
            the guesswork and inconvenience. We want to provide a seamless travel experience
            from start to finish, where gratuities are included for guides, drivers, for luggage handling
            services and for staff at your included meals`,
    // image: require("../../../assets/images/tripMerchantJourneys/"),
  },
  {
    heading: "Expert Local Guides.",
    description: " Knowledgeable. Friendly. Leaders.",
    detail: `Guides are carefully chosen for all Trip Merchant Journeys programs,
            based on their experience, knowledge, customer service, and friendliness. TMJ
            continues to select the same local guides for upcoming departures, after receiving
            positive customer feedback! The guides’ ability to communicate effectively with the group
            is important, while demonstrating a personable, and helpful approach is also an
            additional advantage`,
    image: require("../../../assets/images/tripMerchantJourneys/local expert guides.png"),
  },
];

const TripMerchantJourneys = () => {
  const [data, setData] = useState();

  const [random, setRandom] = useState(
    Math.floor(Math.random() * imageBanner.length)
  );
  const [modalData, setModalData] = useState(initialValue);

  const showModal = () => {
    setTimeout(
      () =>
        setModalData({
          title: "Trip Merchant Journeys",
          show: true,
          navigate: WebRoutes.generalSubscription,
        }),
      10000
    );
  };

  useEffect(() => {
    showModal();
    getData("/tripmerchant-journey").then((res) => {
      // console.log(res, "res");
      setData(res);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => setRandom(Math.floor(Math.random() * imageBanner.length)),
      5000
    );
    return () => clearInterval(interval);
  }, []);

  const myRef = useRef(null);
  const executeScroll = (e) => {
    e.preventDefault();
    const header = document.getElementById("headerId");
    const headerHeight = header?.offsetHeight;
    const elementTop = myRef.current?.offsetTop;
    window.scrollTo({
      top: elementTop - (30 + headerHeight),
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <Title
          title={
            data?.data?.attributes?.Title
              ? data?.data?.attributes?.Title
              : "Trip Merchant | Trip Merchant Journeys"
          }
        />
        <HeroSection
          light={true}
          organizationWebLinks={
            data?.data?.attributes?.heroSection?.organizationWebLinks
              ? data?.data?.attributes?.heroSection?.organizationWebLinks
              : true
          }
          heading={
            data?.data?.attributes?.heroSection?.Heading
              ? data?.data?.attributes?.heroSection?.Heading
              : "Trip Merchant Journeys"
          }
          image={
            data?.data?.attributes?.heroSection?.BackgroundImage?.data != null
              ? `${strapiUrl}${data?.data?.attributes?.heroSection?.BackgroundImage?.data?.attributes?.url}`
              : imageBanner[random].src
          }
          bannerClass={"justify-content-center text-center"}
        />
        <About
          heading={
            data?.data?.attributes?.JourneysExperienceHeading
              ? data?.data?.attributes?.JourneysExperienceHeading
              : "The Trip Merchant Journeys Experience"
          }
          logo={require("../../../assets/images/Trip-Merchant-Journeys-Logo-A11-2.png")}
          textDanger={
            data?.data?.attributes?.JourneysExperienceDetail
              ? data?.data?.attributes?.JourneysExperienceDetail
              : tripMerchantJourneysContent
          }
          showBorder={false}
          isMarkdown={true}
        />
        <div className="d-flex justify-content-center" ref={myRef}>
          <a
            href
            className="button-rot text-unselectable"
            onClick={(e) => executeScroll(e)}
          >
            <div data-tooltip="VIEW ALL TRIPS" />
          </a>
        </div>
        <br />
        <div className="border-gray-bottom" />
        <div className="bg-black">
          {data?.data?.attributes?.blocks && (
            <BenefitListStrapi dataBenefits={data?.data?.attributes?.blocks} />
          )}
          {/* <BenefitList dataBenefits={dataBenefits} /> */}
        </div>
        <div />
        <div ref={myRef}>
          <FeaturedTrips api />
        </div>
        <div className="border-gray-bottom" />
        <InfoSection />
        <div className="border-gray-bottom" />
        {data?.data?.attributes?.Ttestimonials && (
          <Testimonials data={data?.data?.attributes?.Ttestimonials} />
        )}
        <div className="border-gray-bottom" />
        {data?.data?.attributes?.CallToActionWithImage && (
          <WarningBanner
            data={data?.data?.attributes?.CallToActionWithImage}
            setModalData={setModalData}
          />
        )}
        <div className="border-gray-bottom" />
      </div>
      <PopupAlert state={modalData} close={() => setModalData(initialValue)} />
    </>
  );
};

export default TripMerchantJourneys;
