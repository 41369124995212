import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopBar from "./Topbar";

const Header = ({ light, organizationWebLinks, link, hide, class_Name }) => {
  const [path, setPath] = useState("/");
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const className = path !== "/" ? "header-dark-fixed" : "header-light-fixed";

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      const headerElement = document.querySelector(`.${className}`);
      if (!hide) {
        if (value > 90) {
          headerElement?.classList.add("header--sticky");
        } else {
          headerElement?.classList.remove("header--sticky");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
     // eslint-disable-next-line
  }, [className, location])

  /*  window.addEventListener("scroll", function () {
     var value = window.scrollY;
     if (value > 0) {
       document.querySelector(`.${className}`)?.classList.add("header--sticky");
     } else {
       document
         .querySelector(`.${className}`)
         ?.classList.remove("header--sticky");
     }
   }); */
  return (
    <div
      className={`--header ${class_Name ? class_Name : ""
        } zindex-popover ${className} `}
        id="headerId"
    >
      <div className="container-xxl ">
        <TopBar
          link={link}
          path={path}
          hide={hide}
          organizationWebLinks={organizationWebLinks}
        />
      </div>
    </div>
  );
};

export default Header;
