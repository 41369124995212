import React from "react";
/* import { FaUser } from "react-icons/fa"; */
import "./input-component.scss";

export const InputComponent = ({
  iconLeft,
  iconRight,
  placeHolder,
  name,
  type,
  value,
  onChange,
  error,
  className,
}) => {
  return (
    <>
      <div
        className={`d-flex align-items-center align-self-start input-container justify-content-between bg-white ${
          error ? "mb-0" : ""
        }`}
      >
        {iconLeft ? iconLeft : <div className="empty" />}
        <input
          autoComplete={false}
          className={`input ${className || ""}`}
          placeholder={placeHolder}
          onChange={onChange}
          name={name}
          value={value}
          type={type}
        />
        {iconRight ? iconRight : <div className="empty" />}
      </div>
      {error ? <h6 className="text-danger error-style mt-1">{error}</h6> : null}
    </>
  );
};
