import React from "react";
import { Link } from "react-router-dom";

const BannerContainer = ({
  heading,
  title,
  buttonText,
  color,
  onClick,
  link,
  bannerClass,
  buttonClass,
  howItWorks = false,
  Tag = "p"
}) => {
  const button_Class = buttonClass
    ? buttonClass
    : " justify-content-center text-center";
  return (
    <div
      className={`container ${!howItWorks
        ? "d-flex flex-grow-1 align-items-center justify-content-center"
        : ""
        }`}
    >
      <div
        className={`_banner flex-column align-content-center align-items-center ${bannerClass ? bannerClass : "justify-content-center"
          }`}
      >
        <div className="wrapper">
          <h1
            className="font-700 font-xxl"
            style={{ color: color ? color : "white" }}
          >
            {heading}
          </h1>
          <div className={`d-flex ${bannerClass ? bannerClass : ""}`}>
            {title ? <Tag>{title}</Tag> : null}
          </div>
          {buttonText ? (
            <div className={`d-flex ${button_Class}`}>
              <Link
                to={!onClick && link ? link : "#"}
                onClick={onClick}
                className="button-rot text-unselectable"
              >
                <div data-tooltip={buttonText} />
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BannerContainer;
