import React from "react";
import RotateButton from "./RotateButton";

const JoinUs = ({ data }) => {
  return (
    <div className="container">
      <div className="_banner--2 d-flex flex-column justify-content-center align-content-center align-items-center text-center">
        <div className="wrapper">
          <h1>
            {data?.attributes?.CTATitle
              ? data?.attributes?.CTATitle
              : "Ready to Join Us?"}
          </h1>
          <div className="d-flex justify-content-center align-items-center text-center">
            <p>
              {data?.attributes?.CTASubTitle
                ? data?.attributes?.CTASubTitle
                : "Your Trip Merchant Journey Begins here!"}
            </p>
          </div>
          <RotateButton
            link={
              data?.attributes?.CTALink ? data?.attributes?.CTALink : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
