import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const SkeletonCardLoader = ({
  height = "300px",
  data = 4,
  carousel = true,
  width = "95%",
  classSkeleton = "col-lg-4 col-md-6 my-2",
}) => {
  return carousel ? (
    <Carousel
      // autoPlay={true}
      infinite={true}
      showDots={true}
      arrows={true}
      responsive={responsive}
    >
      {Array(data)
        .fill()
        .map((data, index) => (
          <div className="my-2 pb-2 mb-4" key={index}>
            <Skeleton height={height} width={width} />
          </div>
        ))}
    </Carousel>
  ) : (
    <div className="row">
      {Array(data)
        .fill()
        .map((data, index) => (
          <div className={classSkeleton} key={index}>
            <Skeleton height={height} width={"100%"} />
          </div>
        ))}
    </div>
  );
};

export default SkeletonCardLoader;
