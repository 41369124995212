import React from 'react';
import { BenefitsCard } from '../common/Data';
import { BenefitsCards } from '../components/BenefitsCards';
import { MainHeading } from '../components/MainHeading';

const PartnershipBenefits = () => {
  return (
    <div className='benefits '>
      <div className='container'>
        <div className='my-2'>
          <MainHeading heading='Partnership Benefits' />
          <div className='benefits-style-2 container my-4'>
            <BenefitsCards mappingData={BenefitsCard} />
          </div>
          <br />
          <hr />
        </div>
      </div>
    </div>
  );
};

export default PartnershipBenefits;
