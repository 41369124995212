import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { RI, MD } from "../../common/Icons";
import Title from "../../components/Title";
import { HeroSection } from "../../components/HeroSection";
import { getData } from "../../common/strapiUrl";
import apiRoutes from "../../api/ApiRoutes";
import ApiCall from "../../api/network";
import { contactUsValidation } from "../../validation";
import toast from "react-hot-toast";
// import { FormFeedback } from "reactstrap";

const maps = [
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.005817392151!2d-119.43478892450295!3d50.029986817550345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537defacb0b1de2b%3A0x1ebcb512d28b4558!2s10185%20Beacon%20Hill%20Dr%2C%20Lake%20Country%2C%20BC%2C%20Canada!5e0!3m2!1sen!2s!4v1686220187621!5m2!1sen!2s",
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.790918782871!2d-114.01802162442806!3d51.03848224485225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717ab9290366f5%3A0xf553882921a4f11c!2s63%20Inglewood%20Park%20SE%20%23510%2C%20Calgary%2C%20AB%20T2G%201B7%2C%20Canada!5e0!3m2!1sen!2s!4v1686220301422!5m2!1sen!2s",
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2858.2876912800193!2d-78.45939922490885!3d44.242321614131264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d5906be7a21d55%3A0x38dcf39be290b539!2s1229%20Stewart%20Line%2C%20Cavan%2C%20ON%20L0A%201C0%2C%20Canada!5e0!3m2!1sen!2s!4v1686220344713!5m2!1sen!2s",
];
const intialdata = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNum: "",
  // noOfEmployees: "",
  // organizationName: "",
  message: "",
};
const ContactUs = () => {
  const [errors, setErrors] = useState({});

  const [data, setData] = useState();

  useEffect(() => {
    getData("/contact-info").then((res) => {
      console.log(res?.data?.attributes, "res");
      setData(res?.data?.attributes);
    });
  }, []);
  const locationRef = useRef(null);
  const [active, setActive] = useState(-1);
  const [formData, setFormData] = useState(intialdata);
  const handleClickOutside = (event) => {
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setActive(-1);
    }
  };

  const onChangeInputs = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (Object.keys(errors).length > 0) {
      if (errors.hasOwnProperty(name)) delete errors[name];
    }
    return;
  };

  const onSubmit = async (e) => {
    console.log(formData, "contact us from");

    const validations = contactUsValidation(formData);
    toast.dismiss();
    if (Object.keys(validations).length > 0) {
      setErrors(validations);
      return;
    }
    e.preventDefault();
    toast.dismiss();

    let formDataApi = new FormData();
    formDataApi.append("first_name", formData.firstName);
    formDataApi.append("last_name", formData.lastName);
    formDataApi.append("email", formData.email);
    formDataApi.append("phone_number", formData.phoneNum);
    formDataApi.append("message", formData.message);
    const apiResponse = await ApiCall.any(apiRoutes.contactUs, {}, formDataApi);
    if (apiResponse === "Notified") {
      setFormData(intialdata);
      toast.success("Successfull");
    } /*  else {
      toast.error("Something Went Wrong");
    } */
  };

  const selectStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: "gray",
      border: "none",
      height: "44px",
      boxShadow: "none",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "gray",
      fontWeight: "400",
    }),
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Title title={"Trip Merchant | Contact Us"} />

      <HeroSection
        heading={"How can we help you?"}
        title={"YOUR JOURNEY STARTS HERE"}
        color="#FFFF"
        image={require("../../assets/images/contactus.jpg")}
        bannerClass={"justify-content-center text-center"}
      />
      <div className="bg-trip-primary d-flex justify-content-center container my-3 py-2">
        <Col lg={7} className="form-width-container">
          <div className="d-flex justify-content-center mb-4">
            <h3 className=" w-75 text-center font-600">
              Contact the Trip Merchant Team
            </h3>
          </div>
          <hr />
          {inputData.map((input, index) => (
            <div key={index}>
              <p className="m-0 font-md font-300">{input.title}</p>
              {input.type === "text" || input.type === "email" ? (
                <>
                  <input
                    style={{ marginBottom: 0 }}
                    autoComplete={false}
                    className="inputs"
                    type={input.type}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />
                  {errors && errors[input.name] && (
                    <div className="text-danger">{errors[input.name]}</div>
                  )}
                </>
              ) : input.type === "select" ? (
                <Select
                  options={input.options}
                  placeholder={"Select something"}
                  clearable={false}
                  styles={selectStyles}
                  onChange={(change) => {
                    setFormData({ ...formData, noOfEmployees: change.id });
                  }}
                  defaultValue={input.options[0]}
                  className="inputs-select"
                />
              ) : (
                <>
                  <textarea
                    autoComplete={false}
                    className="inputs"
                    style={{ height: "200px", marginBottom: 0 }}
                    name={input.name}
                    onChange={onChangeInputs}
                    value={formData[input.name]}
                  />
                  {errors && errors[input.name] && (
                    <div className="text-danger">{errors[input.name]}</div>
                  )}
                </>
              )}
            </div>
          ))}
          <div
            onClick={onSubmit}
            className="hover-effect mt-2 mb-5 py-2 bg-cust-primary text-white font-lg font-500 text-center text-unselectable border-radius"
          >
            Send
          </div>
        </Col>
      </div>
      <div className="my-1 py-1 container">
        <h3 className="text-center my-1 py-1">Find Us at</h3>
        <Row className="align-items-center my-1 py-1">
          <Col lg="6" className="order-lg-1 order-2 my-1 py-1">
            <h3>Our Contact Info</h3>
            <div className="d-flex flex-column my-1 py-1">
              <small>Contact us</small>
              <div className="d-flex my-1 py-1 align-items-center cursor-pointer hover-text">
                <RI.RiCustomerServiceFill className="me-1" />
                <p className="mb-0">
                  {data?.Phone ? data?.Phone : "1-800-481-9739"}
                </p>
              </div>
            </div>
            <div className="d-flex flex-column my-1 py-1">
              <small>Email us</small>
              <div className="d-flex my-1 py-1 align-items-center cursor-pointer hover-text">
                <MD.MdAlternateEmail className="me-1" />
                <p className="mb-0">
                  {data?.Email ? data?.Email : "info@tripmerchant.com"}
                </p>
              </div>
            </div>
            <div className="d-flex flex-column my-1 py-1" ref={locationRef}>
              <small>Locate us at</small>
              <div
                className="d-flex my-1 py-1 align-items-center cursor-pointer hover-text"
                //  onClick={() => setActive(0)}
              >
                <MD.MdLocationOn className="me-1" />
                <p className="mb-0">
                  {data?.FirstLocation
                    ? data?.FirstLocation
                    : "10185 Beacon Hill Drive, Lake Country, BC V4V 0A9"}
                </p>
              </div>
              <div
                className="d-flex my-1 py-1 align-items-center cursor-pointer hover-text"
                // onClick={() => setActive(1)}
              >
                <MD.MdLocationOn className="me-1" />
                <p className="mb-0">
                  {data?.SecondLocation
                    ? data?.SecondLocation
                    : "510-63 Inglewood Park SE, Calgary AB T2G 1B7"}
                </p>
              </div>
              <div
                className="d-flex my-1 py-1 align-items-center cursor-pointer hover-text"
                //  onClick={() => setActive(2)}
              >
                <MD.MdLocationOn className="me-1" />
                <p className="mb-0">
                  {data?.ThirdLocation
                    ? data?.ThirdLocation
                    : "1229 Stewart Line, Cavan, ON L0A 1C0"}
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg="6"
            className="order-lg-2 order-1 d-flex justify-content-center my-1 py-1"
          >
            {active < 0 ? (
              <img
                src={require("../../assets/images/map.png")}
                width={"100%"}
                alt="map"
              />
            ) : (
              <iframe
                src={maps[active]}
                width="100%"
                height="300"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title={maps[active]}
              ></iframe>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;

const inputData = [
  {
    name: "firstName",
    title: "First Name (required)",
    type: "text",
  },
  {
    name: "lastName",
    title: "Last Name (required)",
    type: "text",
  },
  {
    name: "phoneNum",
    title: "Phone Number (required)",
    type: "text",
  },
  {
    name: "email",
    title: "Email Address (required)",
    type: "email",
  },
  // {
  //   name: "whomToContact",
  //   title: "Who would you like to Contact? (required)",
  //   type: "select",
  //   options: [
  //     { label: "Tom MacLean", id: "Tom MacLean" },
  //     { label: "Ryan Mikucki", id: "Ryan Mikucki" },
  //     { label: "General Contact", id: "General Contact" },
  //   ],
  // },
  // {
  //   name: "organizationName",
  //   title: "Organization Name (required)",
  //   type: "text",
  // },
  {
    name: "message",
    title: "Your Message (required)",
    type: "text area",
  },
];
