import React from "react";
import CardWidgets from "./CardWidgets";
import { infoSectionCards } from "../../common/Data"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowDimensions from "../../hooks/dimension"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const InfoSection = ({ hide = false }) => {
  const { width } = useWindowDimensions();
  return (
    <div className="info-section p-4">
      <div className="container-xxl pt-4">
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center text-center flex-column">
            <h3>Travelling with Trip Merchant Journeys
            </h3>
            <div className={"logo my-2"}>
              <img
                src={require("../../assets/images/Trip-Merchant-Journeys-Logo-A11-3.png")}
                alt="tripMerchant"
              />
            </div>
          </div>
        </div>
        <div className="py-4">
          <Carousel
            responsive={responsive}
            autoPlay={width <= 1024 ? true : false}
            infinite={true}
            showDots={false}
            arrows={false}
            draggable={width <= 1024 ? true : false}
          >
            {infoSectionCards?.map((data, index) => (
              <CardWidgets data={data} key={index} hide={hide} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
