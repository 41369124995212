import React from 'react';
import { IM } from '../../common/Icons';
import { HeroSection } from '../../components/HeroSection';
import Title from '../../components/Title';
import { MainHeading } from '../../components/MainHeading';
import WebRoutes from '../WebRoutes';
import { Link } from 'react-router-dom';
import { employeeBenefitCard } from '../../common/Data';
import { BenefitsCards } from '../../components/BenefitsCards';
import HowItWorks from '../../views/HowItWorks';

/* const benefits = [
  'Free Branded Travel Website',
  'Increase Engagement',
  'Exclusive Membership Savings',
  'Loyalty Program',
  'Password Protected/Members Only Access',
  'Increase Employee Retention',
]; */

export const EmployersPage = () => {

  return (
    <>
      <Title title={'Trip Merchant | Employers'} />

      <HeroSection
        heading={'Employers'}
        title='Offer Your Employees Unforgettable Travel Experiences'
        buttonText={'Request Demo'}
        link={WebRoutes.employersForm}
        color={'#F25926'}
        image={require('../../assets/images/employers.jpg')}
        bannerClass={' justify-content-center text-center'}
      />
      <div className='bg-trip-primary'>
        <div className='container py-3'>
          <MainHeading heading='Employers' />
          <div className='card p-3 mb-3 mx-lg-5 my-4 '>
            <h4 className='text-center cust-primary-color'>
              FEATURES AND BENEFITS
            </h4>
            <IM.ImAirplane
              size={40}
              className='align-self-center cust-primary-color my-3'
            />
            <p className='text-center font-600 font-lg'>
              Free Branded Travel Website Platform that is password protected
              for your members only access
            </p>
            <p className='text-center font-600 font-lg'>
              Exclusive membership savings that are negotiated by Trip Merchant
              with a wide variety of travel suppliers, whom represent different
              travel styles in order to accommodate many diverse interests
            </p>
            <p className='text-center font-600 font-lg'>
              Enhances your Employee Benefits package (employee acquisition and
              retention)
            </p>
            <p className='text-center font-600 font-lg'>
              Trip Merchant manages all the content on the site on your behalf
              (*requiring no time commitment from any of your staff members)
            </p>
            <p className='text-center font-600 font-lg'>
              Loyalty Program to provide employees with additional travel
              rewards
            </p>
            <p className='text-center font-600 font-lg'>
              The latest travel news keeping your employees informed and up to
              date
            </p>
            <p className='text-center font-600 font-lg'>
              Build a strong sense of community within your organization
            </p>
          </div>
          <Link>
            <div className='hover-effect mx-5 my-4 py-4 bg-cust-primary text-white font-xl font-500 text-center text-unselectable border-radius'>
              Request Demo
            </div>
          </Link>
          <HowItWorks heading={"Application Process"} hide={true}/>
          <div className='benefits '>
            <div className='container'>
              <div className='my-2'>
                <MainHeading heading='Employer Benefits' />
                <div className='benefits-style-2 container my-4'>
                  <BenefitsCards mappingData={employeeBenefitCard} />
                </div>
                <br />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
