import React from "react";
import About from "../../views/About";
import Title from "../../components/Title";
import { Benefits } from "../../common/Data";
import { HeroSection } from "../../components/HeroSection";
import { BenefitsCards } from "../../components/BenefitsCards";
import WebRoutes from "../WebRoutes";

const BenefitsPage = () => {
  return (
    <>
      <Title title={"Trip Merchant | Benefits"} />
      <HeroSection
        heading={"Partnership Benefits"}
        title={"Enhance Your Organizations Benefit Program"}
        buttonText={"Contact Us"}
        color="#F25926"
        image={require("../../assets/images/benefits.jpg")}
        link={WebRoutes.ContactUs}
        bannerClass={"justify-content-center text-center"}
      />

      <About
        heading={"Partnership Benefits"}
        subheading={"HERE’S WHY YOU SHOULD PARTNER WITH TRIP MERCHANT"}
        detailsArray={[
          "Travel is the #1 requested benefit for many organizations.",
          "Trip Merchant leverages their relationships in the global travel marketplace to bring you great travel benefits and savings in order to enhance your member benefit program.",
          "The purpose is to provide an easy to use platform, while offering great service by seamlessly connecting your members or employees with their desired travel provider.",
          "There are many great reasons to partner with us. Below we have listed some of the key reasons why.",
        ]}
        showBorder={true}
      />
      <About
        heading={"Your Beautiful Modern Professional Travel Website"}
        subheading={"PUBLISHED WITHIN 2 WEEKS"}
        detailsArray={[
          "Your branded travel website will be up and running within 2 weeks.",
          "We will manage all of the content updates including video, travel news and tips, special savings, e-newsletters and more.",
        ]}
        showBorder={false}
      />
      <About
        // heading={"The Trip Merchant Difference"}
        subheading={null}
        detailsArray={[]}
        showBorder={false}
      />
      <div className="container">
        <div className="d-flex justify-content-center my-2 py-2">
          <img
            className="image-style-1"
            src={require("../../assets/images/benefits.png")}
            alt=""
          />
        </div>
      </div>
      <div className="container benefits-style-2 mb-3">
        <hr />
        <div className="d-flex justify-content-center">
          <h3>The Trip Merchant Difference</h3>
        </div>
        <BenefitsCards mappingData={Benefits} />
      </div>
    </>
  );
};

export default BenefitsPage;
