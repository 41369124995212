import React from 'react'

const HorizontalTextLIne = ({ text = "OR" }) => {
    return (
        <div className="d-flex align-items-center px-2 mx-md-2">
            <div className="horizontal-line" />
            <span className="mx-md-2">{text}</span>
            <div className="horizontal-line" />
        </div>
    )
}

export default HorizontalTextLIne