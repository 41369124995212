import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./home-page.scss";

export const HomePageCards = ({ data }) => {
  const [content, setContent] = useState(
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-once="true"
      className="text-label"
    >
      {data?.textLabel}
    </div>
  );
  // eslint-disable-next-line 
  const [mouseIn, setMouseIn] = useState(false);
  const contentHandler = () => {
    setContent(

      <Link
        data-aos="zoom-in"
        data-aos-duration="800"
        data-aos-once="true"
        to={data?.link}
        target={data?.anchor && "_blank"}
        className="text-white mt-3 btn-styles w-100 align-text-center"
      >
        {data?.buttonLabel}
      </Link>
    );
    setMouseIn(true);
  };
  const resetContent = () => {
    setContent(
      <div
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-once="true"
        className="text-label"
      >
        {data?.textLabel}
      </div>
    );
    setMouseIn(true);
  };
  return (
    <div
      className="d-flex col-sm-5 col-md-3 col-md m-3 flex-column card-container align-items-center justify-content-center shadow rounded"
      onMouseEnter={contentHandler}
      onMouseLeave={resetContent}
    >
      {content}
    </div>
  );
};
