import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/Button/PrimaryButton/PrimaryButton";
/* import { AuthHeader } from "../../../components/Header/AuthHeader/AuthHeader"; */
import Header from "../../../components/Header/Navbar/Header";
import WebRoutes from "../../WebRoutes";
import { HomePageBottomIcons } from "../HomePage/HomePageBottomIcons";
import "./forget-password.scss";
import { forgotPasswordValidation } from "../../../validation";
import Title from "../../../components/Title";
import apiRoutes from "../../../api/ApiRoutes";
import ApiCall from "../../../api/network";
import { toast } from "react-hot-toast";

export const ForgetPassword = () => {
 
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const signUpHandler = async () => {
    setValidations(email);
    if (error.length > 0) {
      return;
    }
    setLoading(true);
    let formDataApi = new FormData();
    formDataApi.append("email", email);
    const response = await ApiCall.any(
      apiRoutes.forgotPassword,
      {},
      formDataApi
    );
    if (response !== -1) {
      toast.dismiss();
      toast.success(
        "The reset code has been sent to your email" ||
          response?.message ||
          "Email for resetting password has been sent"
      );
      navigate(WebRoutes.signin);
    }
    setLoading(false);
  };

  const setValidations = (email) => {
    const er_ror = forgotPasswordValidation(email);
    setError(er_ror);
  };
  /* d-flex justify-content-center align-items-between */
  return (
    <>
      <Title title="Trip Merchant | Forget Password" />

      <div className="full-screen-bg" style={{ minHeight: "100vh" }}>
        <Header
          light={true}
          hide={true}
          organizationWebLinks={true}
          /* link={"register"} */
          class_Name="header-bg-transparent"
        />
        <div className="container main-container d-flex justify-content-between flex-column">
          {/* <AuthHeader color="#e16e44" />
           */}

          <div className="d-flex row justify-content-center forget-password-form-container">
            <div className="signup-fields-container my-3 d-block col-11 col-sm-10 col-md-8 col-lg-5 text-white">
              <h1 className="signin-heading">Forgot Password?</h1>
              <div
                className={`d-flex flex-column align-self-start input-error-container ${
                  error ? "mb-0" : ""
                }`}
              >
                <input
                  autoComplete={false}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="signin-input"
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                {error ? (
                  <h6 className="mt-1 text-danger error-style text-start">
                    {error}
                  </h6>
                ) : null}
              </div>

              <PrimaryButton
                onClick={signUpHandler}
                title="SUBMIT"
                style={{
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "5px 0 12px",
                  height: "40px",
                }}
                loading={loading}
              />

              <div>
                <p className="signup-text">
                  New to Trip Merchant?{" "}
                  <Link
                    to={WebRoutes.signup}
                    className="text-unselectable text-white"
                  >
                    Register Now
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2 flex-row justify-content-end align-items-center">
            <HomePageBottomIcons />
          </div>
        </div>
      </div>
    </>
  );
};
