import React from 'react';
import { FA, TF, MD } from '../common/Icons';
import { socialLinks } from '../pages/SocialMediaLinks';

const SocialIcons = () => {
  return (
    <div className='d-flex justify-content-around'>
      <a
        href={socialLinks.facebook}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='social-icons'>
          <FA.FaFacebookF />
        </div>
      </a>
      <a
        href={socialLinks.linkedin}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='social-icons'>
          <FA.FaLinkedinIn />
        </div>
      </a>
      <a
        href={socialLinks.youtube}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='social-icons'>
          <TF.TfiYoutube />
        </div>
      </a>
      <a
        href={socialLinks.mail}
        className='d-flex justify-content-center align-items-center'
      >
        <div className='social-icons'>
          <MD.MdEmail />
        </div>
      </a>
    </div>
  );
};

export default SocialIcons;
