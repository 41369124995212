const signUpValidations = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }

  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }
  if (!state.password) {
    errors.password = "*Password is required";
  }
  if (state.password && state.password.length < 6) {
    errors.password = "*Password must be at least six characters long";
  }
  if (state.firstName && state.firstName.length < 3) {
    errors.firstName = "*First name must be at least three characters long";
  }
  /*  if(state.acceptPolicy === false){
    errors.acceptPolicy = "Please agree to over terms and privacy policy";
  } */

  return errors;
};

const generalSubValidations = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }
  if (state.firstName && state.firstName.length < 3) {
    errors.firstName = "*Must be at least three characters long";
  }
  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }
  if (!state.password) {
    errors.password = "*Password is required";
  }
  if (state.password && state.password.length < 6) {
    errors.password = "*Password must be six characters long";
  }
  if (state.password !== state.confirmPassword) {
    errors.confirmPassword = "*Password and confirm password does not match";
  }

  return errors;
};

const logInValidations = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.password) {
    errors.password = "*Password is required";
  }

  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }

  return errors;
};

const forgotPasswordValidation = (email) => {
  let error = "";
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    error = "*Email is required";
  }
  if (email && !re.test(email)) {
    error = "*Enter a valid email";
  }

  return error;
};

const ResetPasswordValidation = (data) => {
  let errors = {};
  if (!data.newPassword) {
    errors.newPassword = "Password is required";
  }
  if (data.newPassword?.length < 8) {
    errors.newPassword = "Password Should be at-least 8 character longer";
  }
  if (!data.confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  }
  /* if(data.confirmPassword?.length<8){
    errors.confirmPassword="Confirm password should be at-least 8 character longer"
  } */
  if (
    data.newPassword &&
    data.confirmPassword &&
    data.newPassword !== data.confirmPassword
  ) {
    errors.confirmPassword = "Password and Confirm Password does not match";
  }

  return errors;
};

const RequestForms = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }
  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }
  if (state.hasOwnProperty("website") && !state.website) {
    errors.website = "*Website is required and should be valid";
  }
  if (!state.organizationName) {
    errors.organizationName = "*Organization name is required";
  }
  if (!state.message) {
    errors.message = "*Message is required";
  }
  // if (state.message && state.message.length < 50) {
  //   errors.message = "*Message should be at-least 50 character long";
  // }
  // if (state.message && state.message.length > 100) {
  //   errors.message = "*Message should be not be longer than 100 character";
  // }

  return errors;
};

const DemoForms = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }
  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }
  if (!state.message) {
    errors.message = "*Message is required";
  }
  // if (state.message && state.message.length < 50) {
  //   errors.message = "*Message should be at-least 50 character long";
  // }
  // if (state.message && state.message.length > 100) {
  //   errors.message = "*Message should be not be longer than 100 character";
  // }

  return errors;
};

const newsLetterValidation = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }

  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }

  return errors;
};
const contactUsValidation = (state) => {
  let errors = {};
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!state.firstName) {
    errors.firstName = "*First name is required";
  }

  if (!state.lastName) {
    errors.lastName = "*Last name is required";
  }
  if (!state.phoneNum) {
    errors.phoneNum = "*Phone no is required";
  }
  if (!state.message) {
    errors.message = "*Message is required";
  }
  if (!state.email) {
    errors.email = "*Email is required";
  }
  if (state.email && !re.test(state.email)) {
    errors.email = "*Enter a valid email";
  }

  return errors;
};

export {
  signUpValidations,
  generalSubValidations,
  logInValidations,
  forgotPasswordValidation,
  RequestForms,
  ResetPasswordValidation,
  DemoForms,
  newsLetterValidation,
  contactUsValidation,
};
