import React, { useState, useEffect } from "react";
import Title from "../../../components/Title";
import { HeroSection } from "../../../components/HeroSection";
import About from "../../../views/About";
import { imageBanner } from "../../../common/Data";
import RewardsDetail from "./RewardsDetail";
import { rewardsDataBenefits, rewardsHtmlTextDanger } from "./data";
import "./general-subscription-page.scss";
import { getData, strapiUrl } from "../../../common/strapiUrl";
import RewardsDetailStrapi from "./RewardsDetailStrapi";
import CollapsedMenuStrapi from "../../../components/CollapsedMenuStrapi";
import CallToAction from "../../../components/CallToAction";

const Rewards = () => {
  const [data, setData] = useState();
  const [active, setActive] = useState("");
  const [random, setRandom] = useState(
    Math.floor(Math.random() * imageBanner.length)
  );
  useEffect(() => {
    getData("/rewards-program").then((res) => {
      // console.log(res, "res");
      setData(res);
    });
  }, []);
  useEffect(() => {
    const interval = setInterval(
      () => setRandom(Math.floor(Math.random() * imageBanner.length)),
      5000
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {" "}
      <Title
        title={
          data?.data?.attributes?.Title
            ? data?.data?.attributes?.Title
            : "Trip Merchant | Trip Merchant Rewards"
        }
      />
      <HeroSection
        light={true}
        organizationWebLinks={
          data?.data?.attributes?.heroSection?.organizationWebLinks
            ? data?.data?.attributes?.heroSection?.organizationWebLinks
            : true
        }
        heading={
          data?.data?.attributes?.heroSection?.Heading
            ? data?.data?.attributes?.heroSection?.Heading
            : "Trip Merchant Journeys"
        }
        image={
          data?.data?.attributes?.heroSection?.BackgroundImage?.data != null
            ? `${strapiUrl}${data?.data?.attributes?.heroSection?.BackgroundImage?.data?.attributes?.url}`
            : imageBanner[random].src
        }
        bannerClass={"justify-content-center text-center"}
      />
      <div className="bg-black">
        {/* <div className="border-gray-bottom" /> */}
        <>
          {data?.data?.attributes?.abouts && (
            <RewardsDetailStrapi
              dataBenefits={data?.data?.attributes?.abouts}
            />
          )}
        </>
      </div>
      {data?.data?.attributes?.CallToAction && (
        <div className="bg-black">
          <CallToAction element={data?.data?.attributes?.CallToAction} />
        </div>
      )}
      <div className="border-gray-bottom" />
      {/* <>
        <div className={`benefit-container1 `}>
          <div className={`container row px-lg-5 p-3 py-lg-4 `}>
            <div className={`col-md-12 text-center`}>
              {data?.data?.attributes?.FAQs?.length > 0
                ? data?.data?.attributes?.FAQs?.map((data, index) => (
                    <CollapsedMenuStrapi
                      data={data}
                      key={index}
                      className="reward-loyalty-program"
                      centered={true}
                      setActive={setActive}
                      active={active}
                      font="font-lg"
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </> */}
    </>
  );
};

export default Rewards;
