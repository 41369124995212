// eslint-disable-next-line
const POST = "POST";
// eslint-disable-next-line
const GET = "GET";
// eslint-disable-next-line
const DELETE = "DELETE";
// eslint-disable-next-line
const PUT = "PUT";
// eslint-disable-next-line
const PATCH = "PATCH";

// eslint-disable-next-line
export default {
  //Organization Signup
  signUpOrganization: {
    method: POST,
    url: "/organizer/authentication/register",
  },
  signUpEmployee: {
    method: POST,
    url: "/employee/authentication/register",
  },

  signUpMember: {
    method: POST,
    url: "/member/authentication/register",
  },
  signInMember: {
    method: POST,
    url: "/member/authentication/login",
  },
  signUpTravelSupplier: {
    method: POST,
    url: "/supplier/authentication/register",
  },
  forgotPassword: {
    method: POST,
    url: "/member/authentication/forget-password",
  },
  resetPassword: {
    method: POST,
    url: "/member/authentication/password/reset",
  },
  subscribe: {
    method: POST,
    url: "/subscribe",
    useAuth: true,
  },
  scheduleDemo: {
    method: POST,
    url: "/schedule-demo/create",
    useAuth: true,
  },
  getAllTrips: {
    method: GET,
    url: "/trip-merchant-journeys-group-departures",
    useAuth: false,
  },
  generalFilter: {
    method: GET,
    url: "/trip/filter",
    useAuth: false,
  },
  contactUs: {
    method: POST,
    url: "member/contact-us",
    useAuth: false,
  },
  checkUser: {
    method: POST,
    url: "member/check_user",
    useAuth: false,
  },

  verification: (id, hash) => {
    return {
      method: GET,
      url: `/member/email/verify/${id}/${hash}`,
      useAuth: false,
    };
  },
};
