import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CollapsedMenu from "../../../components/CollapsedMenu";
import WebRoutes from "../../WebRoutes";
import ReactMarkdown from "react-markdown";
import { strapiUrl } from "../../../common/strapiUrl";
import CollapsedMenuStrapi from "../../../components/CollapsedMenuStrapi";

const RewardsDetailStrapi = ({ dataBenefits }) => {
  const location = useLocation();
  console.log(dataBenefits, "dataBenefits");
  const FAQdata = [
    {
      id: 4,
      Title: "How it Works",
      detail:
      location.pathname === '/rewards-program'? "Your Trip Merchant Loyalty Account is activated as soon as you have made your first booking! Once you have departed on your trip, your Travel Dollars that you have earned will be deposited into your account. These Travel Dollars will be available for you to use for future travel." 
        :"Your Trip Merchant Loyalty Account is activated as soon as you make your first booking! Once notified by us you will be able to register below where it says “Loyalty Program Sign Up” Once you have departed on your trip, your Travel Dollars will be added to your account automatically",
    },
    {
      id: 5,
      Title: "Earning Travel Dollars",
      detail:
      location.pathname === '/rewards-program'? "Trip Merchant Loyalty Dollars can be accumulated over time to cover the cost of an entire trip, or simply be applied towards your next travel booking with Trip Merchant. The choice is yours!\n\nThese Trip Merchant Loyalty Dollars are over and above any additional Loyalty Savings that many travel suppliers may currently be offering. Enabling you to increase your savings!\n*You will earn 2% in Trip Merchant Loyalty Dollars on all land/cruise travel products purchased through Trip Merchant.\n\n*Rewards cannot be earned for any of the following: airfare-only bookings; taxes; fuel surcharges; travel visa cost port charges; non-commissionable cruise fares; insurance; Subscription fees; hotel stays less than five nights; deposits; gift cards or prepaid card products; cash back; all inclusive packages; or any other products or services that we may specify from time to time."
      :"Travel Dollars can be accumulated over time to cover the cost of an entire trip, or be applied at anytime as a credit towards a trip. The choice is yours!\n\nThese Travel Dollars are over and above any additional Loyalty Savings that many travel suppliers may currently be offering 2% Cash Rewards on all land/cruise travel product purchased (excluding airfare/pre and post nights/taxes/port charges/insurance/Booking.com reservations).\n\n*You can check your loyalty balance at any time by clicking on the “Check Loyalty Balance” on the link below\n\nAny questions? Email us at loyalty@tripmerchant.com",
    },
    {
      id: 6,
      Title: "Redeeming Your Travel Dollars",
      detail:
      location.pathname === '/rewards-program'?"Email us at loyalty@tripmerchant.com before you make your final payment on your selected Trip Merchant journey or Trip Merchant long stay, so we can apply your Trip Merchant Loyalty Dollars as a discount. If you would like to redeem your Trip Merchant Loyalty Dollars towards any other travel supplier you have booked through Trip Merchant we will e-transfer you the funds at trip departure.\n\n*Rewards cannot be redeemed for any of the following: airfare-only bookings; taxes; fuel surcharges; travel visa cost port charges; non-commissionable cruise fares; insurance; Subscription fees; hotel stays less than five nights; deposits; gift cards or prepaid card products; cash back; all inclusive packages; or any other products or services that we may specify from time to time."
      :"Email: loyalty@tripmerchant.com before you make final payment with your selected Trip Merchant Travel Experience\n\nWe require your final payment invoice, and will look after the redemption of Travel Dollars on your behalf",
    },
  ];
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  return (
    <div>
      {dataBenefits.map(
        (
          {
            Title,
            detail,
            image,
            loyaltyProgram,
            bgIsBlack,
            topBorder,
            bottomBorder,
          },
          index
        ) => (
          <div
            key={index}
            style={!bottomBorder ? { borderBottom: "none" } : {}}
            className={`benefit-container1 ${
              bgIsBlack ? "bg-black" : "bg-white"
            }  ${topBorder ? "benefit-container-top-border" : ""}`}
          >
            <div
              className={`container d-flex align-items-center row px-lg-5 p-3 py-lg-4 justify-content-center`}
            >
              <div
                className={`col-md-12 d-flex justify-content-center align-items-center text-center`}
              >
                <div className="flex-column">
                  <h2
                    className={` m-0 text-center text-orange`}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true"
                  >
                    {Title}
                  </h2>
                  {image?.data ? (
                    <img
                      src={`${strapiUrl}${image?.data?.attributes?.url}`}
                      // src={require("../../../assets/images/Trip Merchant Loyalty-LOGO-B2.png")}
                      alt={index}
                      className="loyalty-logo my-1 mt-2 py-1"
                    />
                  ) : null}
                  <div
                    className={`danger-html text-center mt-3 ${
                      bgIsBlack ? "text-white" : "text-dark"
                    }`}
                    data-aos="fade-up"
                    data-aos-duration="1600"
                    data-aos-once="true"
                    // dangerouslySetInnerHTML={{
                    //   __html: detail,
                    // }}
                  >
                    <ReactMarkdown>{detail}</ReactMarkdown>
                  </div>
                  {loyaltyProgram
                    ? FAQdata?.map((data) => (
                        <CollapsedMenuStrapi
                          data={data}
                          key={index}
                          className="reward-loyalty-program"
                          centered={true}
                          setActive={setActive}
                          active={active}
                          font="font-lg"
                          isblack={bgIsBlack ? true : false}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default RewardsDetailStrapi;
