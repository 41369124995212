import React, { useState } from "react";
import { Collapse, CardBody, Card, CardTitle } from "reactstrap";
import { RX } from "../common/Icons";

const CollapsedMenu = ({ data, className, centered, font = "fw-normal", isHtmlTextDanger, setActive, active }) => {
    const [show, setShow] = useState();
    return (
        <Card className={`${className ? className : ""}`}>
            <CardTitle
                className="p-2 mb-0 bg-background-primary d-flex justify-content-between align-items-center"
                onClick={() => setActive ? setActive(active === data?.title ? "" : data?.title) : setShow(!show)}
            >
                {
                    centered && <p></p>
                }
                <p className={`mb-0 ${font}`}>{data?.title}</p>
                {show ? <RX.RxCaretUp size={30} /> : <RX.RxCaretDown size={30} />}
            </CardTitle>
            <Collapse isOpen={active === data?.title || show}>
                <CardBody>

                    {
                        data?.detail ? isHtmlTextDanger ? <div
                            className={`danger-html`}
                            dangerouslySetInnerHTML={{
                                __html: data?.detail,
                            }}
                        /> : <p className={font}>{data.detail}</p> : null
                    }
                    {
                        data?.list?.length > 0
                            ?
                            <ul>
                                {
                                    data?.list?.map((data, index) => <li key={index}>{data}</li>)
                                }
                            </ul>
                            : null
                    }
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default CollapsedMenu